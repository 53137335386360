

// Modal Animation 
.animation-wipe {
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: -100vh;
        right: 0;
        z-index: 100;

        .box-1,
        .box-2 {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 0;
                position: absolute;
                top: 0;
                left: 0;
                background: white;

                transition: height 0.5s 0.1s ease-out;
            }

            &:after {
                content: '';
                display: block;
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background: white;

                transition: width 0.5s 0.1s ease-out;
            }
        }

        .box-2 {
            z-index: 2;

            &:before {
                top: auto;
                bottom: 0;
            }

            &:after {
                right: auto;
                left: 0;
            }
        }

        .loader {
            display: block;
            width: 100px;
            height: 100px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-27deg);
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
            
            svg {
                display: block;
                width: 100%;
            }
        }

        &.top {
            top: 0;
        }

        &.show {
            .box-1,
            .box-2 {
                &:before {
                    height: 100%;
                    transition: height 0.5s ease-in;
                }

                &:after {
                    width: 100%;
                    transition: width 0.5s ease-in;
                }
            }

            .loader {
                opacity: 1;
                transition: opacity 0.1s 0.5s ease-in;
            }
        }
    }
}

// display stuff, ignore below this line
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.display-content {
    display: none;
    @media screen and (min-width: 1024px) {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease-out;
        background: white;
        margin: auto;
        position: fixed;
        z-index: 1001;
        display: none;
        top: 0;

        .img{
        display: block;
            background-image: url(../img/popup-image.jpg);
            width: 1280px;
            height: 720px;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            left: 5%;
            cursor: pointer;
        }
        h1 {
            color: white;
            font-size: 34px;
            text-transform: uppercase;
        }
        
        .button-wrap {
            margin-top: 35px;
            text-align: center;
            
            button {
                display: inline-block;
                background: #ffffff;
                border: 0;
                border-radius: 0;
                padding: 15px 25px;
                margin: 0;
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.6);
                cursor: pointer;
                font-family: Montserrat, sans-serif;
                font-size: 18px;
                text-transform: uppercase;
    
            }
        }
        
        &.active {
            transform: scale(50%);
            transition: transform .5s ease-in;
        }
    }
	
}
// Btn close 
.lightbox__close{
    display: none;
    @media screen and (min-width: 1024px) {
        display: flex;
        background-size: 100% 100% center center;
        width: 200px;
        height: 200px;
        border: none;
        background-color: transparent;
        z-index: 12;
        position: absolute;
        bottom: 80%;
        right: 0%;
        cursor: pointer; 
        color: inherit;
    }
    @media screen and (max-width: 1250px) {
        display: none;
    }
    @media screen and (max-width: 1500px) {
        right: -7%;
    }
}





