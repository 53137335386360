#convert-old-account-form {
  padding: 3rem 0;
  .panel {
    border: 0;
    box-shadow: none;
  }
  .panel-heading {
    border-bottom: none;
    padding-top: 0px;
    background: transparent;
    .panel-title {
      font-size: 2.2rem;
      font-weight: 400;
    }
  }
  .message-daide {
    padding: 0px;
  }
  margin-left: 30px;
  margin-right: 20px;
  .intro-conversion {
    margin-bottom: 3px;
    padding: 0px;
  }
  .help-message p a {
    color: $brand-tertiary;
    text-decoration: none;
  }
  .help-message p {
    margin-top: 20px;
    padding: 0px;
    font-weight: normal;
  }
  .first-or, .last-or {
    margin-top: 0%;
    text-align: center;
    span {
      color: $brand-secondary;
    }
  }
  .form-premier-cas, .form-deuxieme-cas, .form-troisieme-cas {
    .fieldset-legend {
      color: $brand-primary;
    }
  }
  .btn-convert {
    margin-left: 27%;
    line-height: 25px;
  }

  // Message édition de compte 

  #mail-edit-confirm {
    text-align: center;
    font-weight: 500;
    margin-bottom: -3rem;
  }

  .email-modifier-message {
    margin-bottom: 2rem;
  }

  .form-item {
    margin-top: 3rem;
  }

  #edit-submit {
    // margin: auto;
    display: block;
  }

  // Message validation 

}

@media screen and (min-width: 576px) {
  #convert-old-account-form {
    .btn-convert {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    .form-item {
      width: 80%;
    }
  }
}
@media screen and (min-width: 768px) {
  #convert-old-account-form {
    .form-item {
      width: 70%;
    }
  }
}
@media screen and (min-width: 992px) {

  #edit-retrieve-by-email, #edit-retrieve-by-credentials, #edit-retrieve-by-number-mbs, #edit-first-or, #edit-last-or {
    min-height: 178px;
  }
  .last-or legend, .first-or legend {
    width: 32px;
    margin: auto;
  }
  .first-or, .last-or {
    .panel-heading span {
      margin-top: 100px;
      display: block;
    }
  }
}
@media screen and (min-width: 1200px) {
  #convert-old-account-form {
    max-width: 1160px;
    margin: auto;
    #conversion-compte {
      .intro-conversion {
        padding: 0px;
        margin-bottom: 30px;
      }
    }
    .form-premier-cas, .form-deuxieme-cas, .form-troisieme-cas {
      .fieldset-legend {
        color: $brand-primary;
      }
    }
    .panel-heading {
      border-bottom: none;
    }
    .btn-convert {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    ::placeholder {
      color: $brand-secondary;
      font-weight: normal;
    }
    .first-or, .last-or {
      margin: auto;
      legend {
        width: 32px;
        margin: auto;
      }
      span {
        color: $brand-secondary;
      }
    }
    .form-item {
      border-color: $gray-lightest;
      border-width: 1px;
      width: 100%;
    }
  }
}
