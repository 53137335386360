.front {
  .paragraphs-item-texte {
    background-color: $gray-lightest;
    margin-top: 0;
    @media only screen and (min-width: 768px) {
      margin-top: -7.6rem;
    }
    &::before {
      position: absolute;
      background-color: rgba(52,27,58,0.8);
      z-index: 0;
      content: "";
      width: 100%;
    }
  }
}
.devenir-membre {
  position: relative;
  background-image: url("../img/bg-devenir-membre.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position-x: 50%;
  &::before {
    position: absolute;
    background-color: $brand-primary;
    z-index: 0;
    opacity: .9;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
  }
  .container {
    position: relative;
    padding: 3rem;
    @media screen and (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .txt-membre {
    @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
    h2 {
      text-align: left;
    }
    @media only screen and (max-width: 767px) {
      display: block;
    }
    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }
  .titre {
    color: $gray-lightest;
    font-size: 2rem;
    h2 {
      color: $gray-lightest;
      font-weight: bold;
      font-size: 3rem;
      margin: 0;
    }
    strong {
      font-weight: 300;
      font-size: 3rem;
      color: $brand-secondary;
    }
  }
  .liste {
    color: $gray-lightest;
    font-size: 1.8rem;
    @media only screen and (min-width: 992px) {
      margin-top: 0;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: .5rem;
        &::before {
          position: absolute;
          margin-left: -3rem;
          font-family: $font-icon;
          content: $fa-var-check-circle;
          font-size: 1.8rem;
          line-height: 1.5;
          color: $brand-secondary;
        }
      }
    }
  }
  .btn {
    @media only screen and (max-width: 767px) {
      display: block;
      margin: auto;
      max-width: 14rem;
    }
    @media only screen and (min-width: 768px) {
      float: right;
    }
  }
}
