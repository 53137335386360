#block-block-7 {
  padding: 2rem;
  background-color: $brand-primary;
  border-radius: 1%;
  color: $gray-lightest;
  margin-bottom: 1rem;
  font-weight: 300;
  .block-title {
    color: $brand-secondary-cam;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  ol {
    padding-left: 0;
  }
  li {
    counter-increment: li;
    line-height: 1.2;
    margin-bottom: 4%;
    list-style: none;
    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    &::before {
      //position: absolute;
      content: counter(li);
      color: white;
      display: inline-block;
      opacity: 1;
      background:$brand-tertiary;
      min-width: 32px;
      font-weight: bold;
      border-radius: 32px;
      padding-left: 0px;
      margin-right: 8px;
      line-height: 32px;
      text-align: center;
    }
  }
}