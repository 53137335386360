//cards

.r-i_cards {
  margin-top: 20px;

  .card {
    text-align: center;
    border-radius: 8px;

    @media screen and (min-width: 768px) {
      width: 32%;
      margin-right: 20px;
      padding-bottom: 20px;
    }

    h2 {
      color: $gray-lightest;
    }

    .btn-primary {
      margin-top: 10px;
    }

    &.cohortes-card {
      background: $brand-primary;

      .btn-primary {
        background: $brand-secondary;
      }
    }

    &.registres-card {
      background: $brand-secondary;
    }

    &.etudes-card {
      background: $brand-tertiary;
      margin-right: 0;
    }
  }
}