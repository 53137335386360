.front {
  .view-id-timeline .view-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
  }
  .entity-paragraphs-item {
    clear: both;
    margin-top: 0;
    margin-bottom: 0;
    @media only screen and (min-width: 768px) {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    &:nth-child(1) {
      margin: 0;
    }
    &:nth-child(2) {
      margin: 1rem 0;
    }
    &:nth-child(3) {
      margin-top: 0;
      margin-bottom: 0;
    }
    &:nth-child(4){
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    &:nth-child(5) {
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
}