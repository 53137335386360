.field-type-taxonomy-term-reference {
  position: relative;

  .field-label {
    font-weight: 400;
    display: inline-block;
  }

  .field-items {
    display: inline-block;
  }

  .field-item {
    margin: 0;
    display: inline;

    &:after {
      content: ", ";
      color: $brand-secondary;
      margin-right: 5px;
    }

    &:last-child:after {
      color: transparent;
    }
  }
}

footer .field-type-taxonomy-term-reference {
  float: left;
}

.taxonomy-list {
  ul {
    list-style: none;
    padding-left: 0;

    li::before {
      content: ' ';
    }
  }
}

.field-type-taxonomy-term-reference .field-item {
  margin-left: 5px;
}

.taxonomy-terms {
  border-bottom: 1px solid $brand-secondary-cam;
  padding-bottom: 15px;
  overflow: auto;

  .btn-primary {
    float: right;
  }
}