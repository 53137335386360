#node-14 {
  #crt {
    text-align: center;
  }
  p {
    text-align: center;
  }

  .btn-form-cnx, .btn-form-crt {
    margin-bottom: 2%;
    margin-top: 2%;
  }
  #avantages {
    position: relative;
    padding: 1rem;
    background-color: $brand-primary;
    color: $brand-secondary;
    h3 {
      color: $gray-lightest;
      padding-left: 10px;
    }
    p {
      margin-top: 6%;
      counter-increment: li;
      margin-left: 16%;
      color: $gray-lightest;
    }
    p::before {
      position: absolute;
      content: counter(li);
      color: $gray-lightest;
      display: inline-block;
      opacity: 1;
      background: $brand-tertiary;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding-left: 0px;
      padding-top: 1px;
      margin-right: 8px;
      margin-left: -40px;
      margin-bottom: 0px;
      margin-top: -5px;
      line-height: 27px;
      text-align: center;
      font-weight:bold;
      font-size: 20px;
    }
  }
  #message-adhesion {
    background-color: $brand-primary;
    color: $brand-secondary;
    padding: 2rem;
    strong {
      display: block;
      color: $gray-lightest;
      padding-bottom: 1rem;
    }
    ul {
      padding-left: 15px;
      margin-bottom: 0;
    }
    li {
      list-style: none;
      color: $gray-lightest;
      padding-left: 10px;
      line-height: 1.5;
      &::before {
        position: absolute;
        content: "•";
        color: $brand-tertiary;
        margin-left: -15px;
        font-size: inherit;
        line-height: 1.3;
      }
    }
    h3 {
      color: $gray-lightest;
      padding-left: 10px;
      font-weight: bold;
    }
  }
  #crt {
    h3 {
      color: $brand-secondary;
      font-size: 2.4rem;
    }
    span {
      color: $brand-tertiary;
      font-size: 16px;
    }
    .abscence-compte {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 0px;
    }
    .text-creation-compte-avec-sso {
      font-size: 18px;
      margin-bottom: 0px;
    }
    .btn-creation-compte {
      background-color: $brand-secondary;
      border-color: $brand-secondary;
    }
  }
  #cnx {
    .forget-pw a {
      color: $brand-tertiary;
      font-weight: bold;
      text-decoration: none;
    }
    h3 {
      color: $brand-primary;
      font-size: 25px;
      font-weight: bold;
    }
    span {
      color: $brand-tertiary;
      font-size: 16px;
    }
    .deja-un-compte {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 0px;
    }
    .message-cnx {
      font-size: 18px;
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    #cnx {
      h3 {
        font-size: 20px;
      }
      .deja-un-compte {
        font-size: 16px;
      }
      .message-cnx {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
      .forget-pw {
        a {
          font-size: 12px;
        }
      }
    }
    #crt {
      h3 {
        font-size: 20px;
      }
      .abscence-compte {
        font-size: 16px;
      }
      .text-creation-compte-avec-sso {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
    }
  }
  @media screen and (min-width: 530px) {
    #avantages {
      position : relative;
      height: 350px;
      max-height: 350px;
    }
  }
  @media screen and (min-width: 768px) {
    #avantages {
      position : relative;
      width: 270px;
      height: 420px;
      max-height:420px;
      padding-left: 2%;
      padding-right: 1%;
      padding-top: 1px;
      margin-left: 69%;
      margin-top: -545px;
      background-color: $brand-primary;
      color: $brand-secondary;
      border-radius: 1%;
      h3 {
        color: $gray-lightest;
      }
      p {
        margin-top: 9%;
        counter-increment: li;
        margin-left: 16%;
        color: $gray-lightest;
        //font-weight:bold;
      }
      p::before {
        position: absolute;
        content: counter(li);
        color: $gray-lightest;
        display: inline-block;
        opacity: 1;
        background: $brand-tertiary;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding-left: 0px;
        padding-top: 1px;
        margin-right: 8px;
        margin-left: -40px;
        margin-bottom: 0px;
        line-height: 27px;
        text-align: center;
        font-weight:bold;
        font-size: 20px;
      }
    }
    #message-adhesion {
      background-color: $brand-primary;
      color: $brand-secondary;
      h3 {
        color: $gray-lightest;
      }
      h3 .creation-compte {
        color: $brand-secondary;
        font-weight: bold;
      }
    }
    #cnx {
      .message-cnx {
        padding-right: 30%;
      }
    }
    #crt {
      .text-creation-compte-avec-sso {
        padding-right: 30%;
      }
    }
  }
  @media screen and (min-width: 992px) {
    #avantages {
      margin-top: -100px;
    }
    #cnx {
      .message-cnx {
        padding-right: 0px;
      }
    }
    #crt {
      .text-creation-compte-avec-sso {
        padding-right: 0px;
      }
    }
  }
}
