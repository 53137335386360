//
// Variables
// --------------------------------------------------

//## Custom colors

$gray-lightest: #ffffff;
$brand-primary-cam: #4E1956;
$brand-secondary: #B7ACA1;
$brand-secondary-cam: #E3DFDB;
$brand-tertiary: #94C11F;
$brand-tertiary-cam: #7BA01A;
$brand-quaternary: #0a7d92;
$brand-quaternary-cam: #075b73;
$brand-success-bg: $brand-tertiary;
$brand-success-border: $brand-tertiary;
$brand-success-link: $gray-lightest;
$brand-warning-bg: #fcf8e3;
$brand-warning-border: #faebcc;
$brand-warning-link: #a81a32;
$brand-danger-bg: #f2dede;
$brand-danger-border: #ebccd1;
$brand-danger-link: #a81a32;
$brand-tertiary-cam-dark : #617f14;
$site-name : #999999;
$brand-general: #db007a;
$brand-event: #F39500;
$brand-recherche: #496707;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$brand-primary: #54455c;
$brand-primary-light: #E6E0E7;
$brand-success: $brand-tertiary;
$brand-info: $brand-primary;
$brand-warning: #a81a32;
$brand-danger: #a81a32;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Global text color on `<body>`.
$text-color : $gray-dark;
//** Global textual link color.
$link-color: $brand-tertiary;
//** Link hover color
$link-hover-color: $brand-tertiary-cam;
//** Link hover decoration.
$link-hover-decoration: none;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-border-radius-large: 8px;
$btn-border-radius-base: 5px;
$btn-border-radius-small: 3px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Background color used for `.table-striped`.
$table-bg-accent: $brand-secondary-cam;
//** Background color used for `.table-hover`.
$table-bg-hover: $brand-secondary-cam;

//== Forms

//** Border color for inputs on focus
$input-border-focus: $brand-primary;

//** Placeholder text color
$input-color-placeholder: $text-color;

//** Default `.form-control` height
$input-height-base: 40px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-border-radius: 0;
$alert-link-font-weight: 300;

$alert-success-bg: $brand-tertiary;
$alert-success-text: $gray-lightest;
$alert-success-border: $brand-tertiary;

$alert-info-bg: $brand-primary;
$alert-info-text: $gray-lightest;
$alert-info-border: $brand-primary;

$alert-warning-bg: $brand-danger;
$alert-warning-text: $gray-lightest;
$alert-warning-border: $brand-danger;

$alert-danger-bg: $brand-danger;
$alert-danger-text: $gray-lightest;
$alert-danger-border: $brand-danger;

//== Panels
//
//##

$panel-body-padding: 1.5rem 2.5rem;
$panel-heading-padding: $panel-body-padding;
$panel-border-radius:$border-radius-large;

$panel-default-text:          $brand-primary;
$panel-default-border:        $brand-secondary-cam;
$panel-default-heading-bg: $brand-secondary-cam;

//== Tooltips
//
//##

//** Tooltip text color
$tooltip-color: $gray-lightest;
//** Tooltip background color
$tooltip-bg: $brand-primary;
$tooltip-opacity: 1;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

//== Popovers
//
//##

//** Popover maximum width
$popover-max-width: 420px;

//** Popover title background color
$popover-title-bg: $brand-secondary-cam;

//== Pagination
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;
$pagination-color: $brand-primary;
$pagination-hover-color: $brand-primary;

//== Pagination
//
//##

$pagination-color: $gray-lightest;
$pagination-bg: $brand-secondary;
$pagination-border: $brand-secondary;

$pagination-hover-color: $text-color;
$pagination-hover-bg: $brand-secondary-cam;
$pagination-hover-border: $brand-secondary-cam;

$pagination-active-color: $gray-lightest;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: $gray-lightest;
$pagination-disabled-border: $gray-lighter;

//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 5px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

// //== Navbar
// $navbar-border-radius: 0;
// $nav-link-hover-bg: transparent;

//== Custom variables
//
//## Réseaux sociaux
$facebook: #3B5998;
$linkedin: #007BB6;
$twitter: #00ACED;

// Background transparent
@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background: rgba($color, $opacity);
}
