#sidebar-wrapper,
.hamburger,
.search-button {
  transition: left 0.5s ease;
}

#sidebar-wrapper,
.hanburger {
  z-index: 995;
}

#sidebar-wrapper {
  position: fixed;
}

.toggled,
.toggled.affix {
  &::before {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    height: 40px;
    content: '';
    // background: $gray-lightest;
  }

  #sidebar-wrapper {
    left: 300px;

    // @media screen and (min-width: 1700px) {
    //   position: static;
    //   float: right;
    //   margin-left: -350px;
    // }
    @media screen and (min-width: 1920px) {
      width: 18%;
    }
  }

  // .search-button {
  //   left: 255px;
  //   z-index: 993;
  //   background: $gray-lightest;

  //   @media screen and (min-width: 1600px) {
  //     left: 30px;
  //   }

  //   @media screen and (min-width: 1630px) {
  //     left: 19px;
  //   }
  // }

  .hamburger {
    // &.is-open {
    //   left: 300px;
    //   z-index: 1000;
    //   &::before {
    //     font-family: $font-icon;
    //     content: $fa-var-times;
    //     font-size: 3rem;
    //     font-weight: 900;
    //     padding: 16px;
    //     background: $gray-lightest;
    //     position: absolute;
    //     right: 15px;
    //     // transition: .8s ease-in-out;

    //     @media screen and (min-width: 768px) and (max-width: 991px) {
    //       padding: 14px;
    //       right: -24px;
    //     }

    //     @media screen and (min-width: 992px) {
    //       position: inherit;
    //       padding: 0;
    //       background: none;
    //       left: 0;
    //       font-size: 4rem;
    //       color: $brand-secondary-cam;
    //     }
    //   }
    //   .menu-text {
    //     display: none;
    //   }
    // }
    @media screen and (min-width: 1600px) {
      left: 15px;
    }

    @media screen and (min-width: 1630px) {
      left: 0;
    }
  }
  
  header > .container {
    &::after {
      @media screen and (max-width: 991px) {
        background: $gray-lightest;
        width: 100%;
        height: 60px;
        max-width: 240px;
        position: absolute;
        z-index: 1;
      }
    }
  }
}

.toggled.affix {
  #sidebar-wrapper {
    top: 0;
  }

  .search-button {
    @media screen and (min-width: 768px) {
      top: 0;
    }
  }

  .hamburger {
    @media screen and (min-width: 768px) {
      top: 0;
    }
  }
}

// .overlay-menu {
//   position: fixed;
//   display: none;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, .7);
//   z-index: 994;
//   &.open-menu {
//     display: block !important;
//   }
// }
