//Gestion footer copyright
footer .footer-copyright {
  padding: 10px 0;
  background-color: $brand-tertiary;
  text-align: center;
  margin-top: 20px;
  span {
    color: $gray-lightest;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
}
