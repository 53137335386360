.media-inline, .media-element-container {
  display: inline-block;
  // top: .5rem;
  position: relative;
}

.file-document {
  .content {
    display: inline-block;
  }
  img {
    display: none
  }
  a {
    &::after, &::before {
      font-family: $font-icon;
    }
    &::before {
      margin-right: .5rem;
    }
    &::right {
      margin-left: .5rem;
    }
  }
  
  &.file-application-pdf a::before {
    content: $fa-var-file-pdf;
    color: #f91d0a;
  }
  &.file--application--vnd.ms-excel a::before {
    content: $fa-var-file-excel;
  }
  &.file--application--vnd.ms-powerpoint a::before {
    content: $fa-var-file-powerpoint;
  }
}

.node-type-actualites {
  .img-headline {
    @media screen and (max-width: 767px) {
      float:none !important;
      margin-bottom: 1rem;
      width: 100%;
      display:block;
    }
    @media screen and (min-width: 768px) {
      float: left;
      margin-right: 1.5rem;
      max-width: 50%;
    }
  }
}
