// 07 UI elements


// Pager

.pagination:not(.tabs--secondary) {
  list-style: none;
  width: 100%;
  text-align: center;
  border-top: 1px solid $brand-secondary-cam;
  padding-top: 1rem;
  border-radius: 0;
  > li {
    display: inline-block;
    @media screen and (min-width: 768px) {
      margin: 0 .5rem;
    }
    &::before {
      content: '';
    }
    &:first-child, &:last-child {
      > a {
        @media screen and (max-width: 767px) {
          font-size: 0;
          &::before {
            font-family: $font-icon;
            font-weight: 900;
            font-size: 1.6rem;
          }
        }
        @media screen and (min-width: 768px) {
          width: 14rem;
        }
      }
    }
    &:first-child {
      // float:left;
      > a {
        @media screen and (max-width: 767px) {
          &::before {
            content: $fa-var-chevron-left;
          }
        }
      }
    }
    &:last-child {
      // float:right;
      > a {
        @media screen and (max-width: 767px) {
          &::before {
            content: $fa-var-chevron-right;
          }
        }
      }
    }
    > a {
      font-weight: 300;
      border-radius: $btn-border-radius-base;
    }
  }
  &.pagination-lg {
    > li > a {
      border-radius: $btn-border-radius-large;
    }
  }

  &.pagination-sm {
    > li > a {
      border-radius: $btn-border-radius-small;
    }
  }
}

// Step

.webform-progressbar {
  margin: 0 auto;
  text-align: center;
  width: 95%;
}
.webform-progressbar-inner {
  height: 3px;
  background-color: $brand-primary-cam;
  margin-bottom: -5px;
}
.webform-progressbar-outer {
  position: relative;
  margin: 0.35em -1px 2em;
  margin-top: 8rem;
  background-color: $brand-secondary-cam;
  height: 4px;
  border: none;
  width: 100%;
}
.webform-progressbar-page {
  position: absolute;
  border: 5px solid $brand-secondary-cam;
  background-color: $brand-secondary;
  border-radius: 63%;
  width: 18px;
  height: 18px;
  margin:-7px -18px;
  color: $brand-secondary;
  &.completed, &.current {
    width: 25px;
    height: 25px;
    margin: -9px -4px;
    border: 5px solid $brand-primary;
    background-color: $brand-primary-cam;
    border-radius: 63%;
    color: $text-color;
    @media screen and (min-width: 768px) {
      width: 35px;
      height: 35px;
      margin: -15px -4px;
    }
    .webform-progressbar-page-label {
      margin-left: -32px;
      top: -35px;
    }
  }
  .webform-progressbar-page-number {
    display: none;
  }
  .webform-progressbar-page-label {
    display: none;
    @media screen and (min-width: 768px) {
      position: relative;
      display: block;
      top: -45px;
      margin-left: -40px;
      width: 9rem;
    }
  }
}

// Date & tag
.submitted {
  display:inline-block;
  clear: both;
  color: $brand-secondary;
  font-size: 1.4rem;
  padding: 1.5rem 0;
  font-style: normal;
  &::before {
    font-family: $font-icon;
    font-weight: 900;
    content: $fa-var-clock;
    margin-right: .5rem;
  }
}

.field-type-taxonomy-term-reference {
  color: $brand-secondary;
  @media screen and (min-width: 768px) {
    display: inline-block;
    margin-left: 2rem;
  }
  .field-items {
    &::before {
      display: inline-block;
      font-family: $font-icon;
      font-weight: 900;
      content: $fa-var-tag;
      margin-right: .5rem;
      font-size: 1.4rem;
    }
  }
  .field-item {
    display: inline-block;
    font-size: 0;
    &::after {
      content: ',';
      font-size: 1.4rem;
      color: $brand-secondary;
      margin-right: .2rem;
      font-weight: 300;
    }
    &:last-child::after {
      content: '',
    }
  }
  a {
    color: $brand-secondary;
    font-weight: 300;
    font-size: 1.4rem;
    &:hover {
      color: $brand-secondary-cam;
      text-decoration: underline;
    }
  }
}

// Modal

.modal-dialog {
  position:absolute;
  /* stylelint-disable-next-line declaration-no-important */
  top:50% !important;
  /* stylelint-disable-next-line declaration-no-important */
  transform: translate(0, -50%) !important;
  margin:auto;
  left:0;
  right:0;
  width:50%;
  height:50%;
}
.modal-content {
  min-height:100%;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
.modal-header {
  .close {
    span {
      font-size: 2.4rem;
      color: $brand-primary;
    }
  }
}
.modal-title {
  font-size: 2.4rem;
}
.modal-footer {
  position:absolute;
  bottom:0;
  left:0;
  right:0;
}

// Tooltips

.tooltip-inner {
  padding: 1rem;
  font-size: 1.4rem;
  text-align: left;
}

// Popover

.popover {
  padding: 0;
}
.popover-title, .popover-content {
  padding: 1.5rem 2.5rem;
}
.popover-title {
  background: $gray-lightest;
}

// Accordion

 .panel-default, #accordion, .accordion, #data-accordion > .panel-default {
  margin-bottom: 3rem;
  &.last-child {
    margin-bottom: 3rem;
  }
  > .panel-heading, fieldset .panel-heading {
    border-bottom: none;
    padding: 1.5rem 1rem 1rem 2rem;
    .panel-title {
      width: 100%;
      display: block;
      font-weight: bold;
    }
    a {
      display:block;
      width: 100%;
      &::after {
        font-family: $font-icon;
        content: $fa-var-chevron-down;
        float: right;
      }
    }
  }
}
