/**********************************
Actualités accueil
**********************************/

.view-display-id-headline_actualites_accueil {
  padding: 0;
}

.home-actualites-tuiles {
  background: $brand-primary url(../img/fond.png) no-repeat;
  background-size: cover;
  padding: 2.5rem 0 0 0;
  h2 {
    color: $brand-secondary-cam;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  .more-link {
    .btn, .btn:active {
      color: $gray-lightest;
      &::after {
        text-indent: 3px;
      }
    }
  }
}

.view-display-id-actualites_accueil {
  .actus-preview, .actus-headline {
    position: relative;
    background: $brand-primary;
    border: .5rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    padding-top: 33.33%;
    @media only screen and (min-width: 768px) {
      padding-top: 31%;
      border-width: 1rem;
    }
    // @media only screen and (min-width: 992px) {
    //   padding-top: 31.66%;
    // }
    @media only screen and (min-width: 1200px) {
      // padding-top: 32.2%;
      padding-top: 26%;
    }
    .img-wrapper {
      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
    a {
      color: $gray-lightest;
      font-weight: 300;
    }
    .overlay {
      position: absolute;
      width: 100.4%;
      height: 100%;
      bottom: 0;
      z-index: 1;
      .bg {
        margin-top: -2rem;
        display: flex;
        align-items: flex-end;
        // background: $gray-base;
        height: 100%;
        width: 100%;
        opacity: .8;
        padding: 1rem;
        min-height: 5rem;
        line-height: 1.2;
        background: linear-gradient(to bottom, rgba(0,0,0,0.0) 25%,rgba(0,0,0,1) 100%);
      }
      .title-wrapper {
        height: 100%;
        text-align: left;
        .title {
          z-index: 2;
          overflow: hidden;
          font-size: 1.6rem;
          font-weight: 400;
          opacity: 1;
          @media only screen and (max-width: 991px) {
            line-height: 1;
          }
        }
      }
    }
    // &:hover {
    //   .bg {
    //     opacity: 1;
    //   }
    // }
  }
  .actus-headline {
    padding-top: 68%;
    @media only screen and (min-width: 767px) {
      // padding-top: 31%;
      border-width: 1rem;
    }
    @media only screen and (min-width: 992px) {
      padding-top: 31.66%;
    }
    @media only screen and (min-width: 1200px) {
      padding-top: 55.5%;
    }
    .overlay {
      left: 0;
      top: 0;
      right: 0;
      .bg {
        margin-top: 0;
        min-height: 8rem;
      }
      .title-wrapper {
        .title {
          font-size: 2rem;
        }
      }
    }
  }
  .actus-preview {
    justify-content: center;
    text-align: center;
    img {
      height: 100%;
    }
    // &:nth-child(1), &:nth-child(4) {
    //   background-color: $brand-tertiary;
    // }
    // &:nth-child(2), &:nth-child(3) {
    //   background-color: $brand-quaternary;
    // }
    @media only screen and (max-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
    @media only screen and (min-width: 767px) {
      .overlay {
        margin-left: -15px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .actus-preview-wrapper {
      padding: 0;
      > .row {
        margin: 0;
      }
    }
  }
}