/* Gestion page president honneur, commission scientifique */
.president-honneur-list,
.commission-scientifique-list,
.commissions-list {
  padding: 0;
  list-style: none;
  // padding-left: 0;

  .president-honneur,
  .commission-scientifique,
  .commissions {
    max-height: 175px;
    box-sizing: border-box;
    margin-bottom: 3rem;

    @media screen and (min-width: 768px) {
      // min-height: 115px;

      &.views-row-odd {
        // padding-left: 0;
        // padding-right: 1rem;
        clear: both;
      }

      &.views-row-even {
        // padding-left: 1rem;
        // padding-right: 0;
      }
    }

    .president,
    .commission-scientifique-item,
    .commissions-item {
      display: flex;

      .president-image,
      .commission-scientifique-image,
      .commissions-image {
        padding-left: 0px;
        padding-right: 0px;
        // display: flex;
        // align-items: center;
        // background: $brand-secondary;

        img {
          max-height: 175px;
          max-width: 135px;
          border: 1px solid $gray-lighter;
        }
      }

      .president-informations,
      .commission-scientifique-informations,
      .commissions-informations {
        padding: 7px;
        background: $brand-secondary-cam;

        @media screen and (min-width: 768px) {
          padding: 10px;
        }

        @media screen and (min-width:992px) {
          padding: 15px;
        }

        h2 {
          font-size: 1.6rem;
          margin: 0px;
          color: $brand-primary-cam;
          font-weight: bold;

          @media screen and (min-width: 992px) {
            font-size: 1.8rem;
          }
        }

        .pres-hopital,
        .pres-service,
        .pres-city,
        .commission-scientifique-hopital,
        .commission-scientifique-service,
        .commission-scientifique-city,
        .commissions-hopital,
        .commissions-service,
        .commissions-city {
          color: $text-color;
          font-size: 1.2rem;

          @media screen and (min-width: 768px) {
            font-size: 1.3rem;
          }

          @media screen and (min-width: 992px) {
            font-size: 1.4rem;
          }
        }

        .pres-specialite,
        .commission-scientifique-specialite .commissions-specialite {
          padding-bottom: 5px;

          @media screen and (min-width: 992px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

.item-list {
  h3 {
    clear: both;
    color: $brand-primary-cam;
    text-align: center;
    margin-bottom: 30px;
  }

  .views-row-first {
    clear: left;
  }
}

.president-honneur-list li::before,
.commission-scientifique-list li::before,
.commissions-list li::before {
  display: none;
}
