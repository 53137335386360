#header-top-right {
  text-align: right;
  padding: 0;
}

#header-top-middle {
  display: none;
  @media screen and (min-width: 1200px) {
    display: block;
  }
}

#header {
  padding-top: 6rem;
  background: $gray-lightest;

  .container {
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
      height: 75px;
    }

    @media screen and (min-width: 992px) {
      height: 165px;
    }
  }

  .affix-top .container {
    @media screen and (min-width: 768px) {
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
