.rteindent1 {
  margin-left: 400px;
}
.rteindent2 {
  margin-left: 80px;
}
.rteindent3 {
  margin-left: 120px;
}
.rteindent4 {
  margin-left: 160px;
}
.alert {
  border: 0 none;
  border-radius: 0;
  color: $gray-lightest;
  min-height: 34px;
  margin-bottom: 22px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  text-shadow: none;
  transition: opacity .3s linear;
}
// Ajout d'une bordure
.notification {
  border-width: 1px;
  border-style: solid;
  padding: 20px;
  margin: 0 0 10px;
}
.border-primary {
  border-color: $brand-primary;
}
.border-success {
  border-color: $brand-success;
}
.border-info {
  border-color: $brand-info;
}
.border-warning {
  border-color: $brand-warning;
}
.border-danger {
  border-color: $brand-danger;
}
