.background-breadcrumb {
  background-image: url("../img/entete.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: $brand-secondary-cam;
  a {
    color: $brand-secondary-cam;
    text-decoration: none;
  }
  .first {
    a {
      font-size: 0;
      &::before {
        font-size: 2.4rem;
        font-family: $font-icon;
        content: $fa-var-home;
        position: relative;
      }
    }
  }
  .delimiter {
    font-size: 0;
    &::before {
      font-size: 1rem;
      font-family: $font-icon;
      content: $fa-var-circle;
      position: relative;
      margin: .5rem;
    }
  }
}
.background-transparent {
  z-index: 10;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.rubrique {
  font-size: 3.2rem;
  font-weight: 300;
  color: $gray-lightest;
  a {
    color: $gray-lightest;
  }
}
.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  border-radius: 6px;
  padding: 0;
  > li {
    margin: 0 .2rem 0 0;
    display: inline;
  }
  > span {
    margin: 0 .2rem 0 0;
    display: inline;
    font-size: 1.4rem;
  }
}
