.view-id-annonces .item-list {
  margin-bottom: 20px;
  padding: 0 0 20px 0;

  h3 {
    background: $brand-primary;
    color: $gray-lightest;
    padding: 10px;
    text-align: center;
    line-height: 30px;
    margin-top: 0;

    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }
}

.view-id-annonces ul {
  list-style: none;
  padding: 0 8px;
  margin-left: 0;

  li {
    clear: both;
    min-height: 60px;
    border-bottom: 1px dashed $brand-secondary-cam;
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
      min-height: 65px;
    }

    @media screen and (min-width: 992px) {
      min-height: 85px;
    }

    &::before {
      content: ' ';
    }

    .date-annonces {
      background: $brand-secondary;
      border-top: 1px solid $brand-secondary-cam;
      border-bottom: 1px solid $brand-secondary-cam;
      padding: 3px 5px;
      border-radius: 5px;

      @media screen and (min-width: 768px) {
        padding: 6px 5px;
      }

      @media screen and (min-width: 992px) {
        padding: 15px 5px;
      }

      @media screen and (min-width: 1200px) {
        width: 6%;
        padding: 10px 5px;
        margin-left: 15px;
        margin-right: 15px;
      }

      .date-annonces-jour,
      .date-annonces-mois {
        display: block;
        text-align: center;
        color: $gray-lightest;
      }

      .date-annonces-jour {
        font-size: 1.8rem;

        @media screen and (min-width: 768px) {
          font-size: 2rem;
          font-weight: 600;
        }
      }

      .date-annonces-mois {
        font-size: 1rem;
        text-transform: uppercase;

        @media screen and (min-width: 768px) {
          font-size: 1.1rem;
        }
      }
    }

    .annonces-info {
      $gray-lightest-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 8px;
      padding-right: 8px;
      line-height: 1.3rem;

      @media screen and (min-width: 768px) {
        line-height: 2.5rem;
      }

      h4 {
        margin-top: 0;
        margin-bottom: 0;
        $gray-lightest-space: normal;
        font-size: 1.3rem;
        max-height: 30px;
        overflow-y: hidden;

        @media screen and (min-width: 768px) {
          max-height: none;
          font-size: 1.6rem;
        }

        @media screen and (min-width: 992px) {
          font-size: 1.8rem;
        }

        a {
          color: $brand-primary;
        }
      }

      .annonce-lieu {
        font-size: 1rem;
        line-height: 1rem;

        @media screen and (min-width: 768px) {
          font-size: 1.2rem;
        }
      }
    }

    .voir-annonces {
      padding: 10px 5px;
      background: $brand-primary;
      color: $gray-lightest;
      text-align: center;
      border-radius: 5px;

      @media screen and (min-width: 768px) {
        padding: 8px 5px;
      }

      @media screen and (min-width: 992px) {
        padding: 17px 5px;
      }

      @media screen and (min-width: 1200px) {
        width: 6%;
        padding: 0;
      }

      a {
        color: $gray-lightest;

        &::before {
          font-family: $font-icon;
          content: $fa-var-angle-right;
          font-size: 2.4rem;

          @media screen and (min-width: 768px) {
            font-size: 3rem;
          }

          @media screen and (min-width: 1200px) {
            font-size: 4.5rem;
          }
        }
      }
    }
  }

  li:last-of-type {
    border-bottom: none;
  }
}

// Gestion block annonces
.page-node-52 {
  .paragraphs-items-full h2 {
    clear: both;
    margin-bottom: 0px;
    font-weight: bold;
    text-align: center;
  }

  .paragraphs-item-block {
    clear: none;
  }
}

.annonces-block {
  padding-left: 0;
  padding-right: 0;

  h2 {
    display: none;
    height: 0;
  }

  h3 {
    margin-bottom: 0;
    padding: 10px;
    background-color: $brand-primary;
    color: $gray-lightest;
    text-align: center;
  }

  .annonces-block-item {
    // border: 1px solid $brand-border;
    // margin-bottom: 20px;
    padding: 10px 0 0;

    .date-annonces {
      padding: 3px 5px;
      width: 17%;

      @media screen and (min-width: 768px) {
        padding: 6px 5px;
        width: 8%;
      }

      @media screen and (min-width: 992px) {
        padding: 4px 5px;
        width: 14%;
      }

      @media screen and (min-width: 1200px) {
        padding: 6px 5px;
        width: 12%;
        margin: 0;
      }

      .date-annonces-jour {
        @media screen and (min-width: 992px) {
          font-size: 2.4rem;
        }
      }

      .date-annonces-mois {
        @media screen and (min-width: 992px) {
          font-size: 1.3rem;
        }
      }
    }

    .annonces-info {
      width: 74%;

      @media screen and (min-width: 768px) {
        width: 86%;
      }

      @media screen and (min-width: 992px) {
        width: 80%;
      }

      @media screen and (min-width: 1200px) {
        width: 82%;
      }
    }

    .voir-annonces {
      padding: 6px 5px;

      @media screen and (min-width: 768px) {
        padding: 8px 5px;
        width: 6%;
      }

      @media screen and (min-width: 992px) {
        margin-top: 5px;
      }

      @media screen and (min-width: 1200px) {
        padding: 0px 5px;
        margin-top: 5px;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }

  .btn-primary {
    padding: 10px 15px;
    margin-bottom: 20px;
  }
}

.annonces-emploiOff-block,
.annonces-remplacementOff-block {
  @media screen and (min-width: 992px) {
    padding-right: 10px;
    margin-bottom: 20px;
  }
}

.annonces-emploiDem-block,
.annonces-remplacementDem-block {
  @media screen and (min-width: 992px) {
    padding-left: 10px;
    margin-bottom: 20px;
  }
}

// Page annonce

.node-annonce-d-emploi-ou-ou-de-rempla, .node-annonces-d-emploi {

  // .field-group-div {
  //   div {
  //     margin-bottom: 2px;
  //   }
  // }

  .annonce-details {
    display: inline-block;
    background-color: $brand-primary;
    padding: 2rem;
    border: 1px solid $brand-primary-cam;
    border-radius: 5px;
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
    line-height: 1.6;
    color: $gray-lightest;
  }


  .field-label {
    color: $brand-secondary;
    font-weight: normal;
  }

  .field-name-field-type-d-annonce, .field-name-field-type-d-offre {
    float: left;
  }

  .field-name-field-type-de-contrat {
    clear: left;
  }

  .field-name-field-lieu div {
    display: inline;
  }

}


// Bouton Déposer

.add-annonces {
  margin-bottom: 20px;
  text-align: right;

  a {
    font-size: 1.4rem;
    color: $gray-lightest;
    background: $brand-primary;
    padding: 10px 15px;
    border-radius: 5px;

    @media screen and (min-width: 992px) {
      font-size: 1.6rem;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

// Bouton Retour

.retour-annonces {
  clear: both;
  .btn {
    display: inline-block;
    margin-right: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    a {
      color: $gray-lightest;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

.page-node-73 {
  .field {
    .field-label {
      font-weight: bold;
      background-color: rgba($brand-secondary-cam, 0.2);
      display: inline-block;
      margin-bottom: 10px;
      padding: 5px;
    }

    .field-items {
      margin-bottom: 15px;
    }
  }
}

#edit-field-contact-annonce-und-0-format,
#edit-field-description-und-0-format,
.group-old-fields {
  display: none;
}
