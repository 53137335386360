// Drupal Message status

.messages {
  padding: 1.5rem 1.5rem 1.5rem 5rem;
  margin-bottom: 2rem;
  a {
    font-weight: bold;
  }
  &::after {
    font-family: $font-icon;
    position: absolute;
    margin-right: 1.5rem;
    top: 1rem;
    font-size: 2.5rem;
  }
  &.status {
    color: $gray-lightest;
    background-color: $brand-success;
    border-color: $brand-success;
    margin: auto;
    a {
      color: $brand-success-link;
    }
    // &::after {
    //   content: "\f058";
    // }
  }
  &.error, &.warning {
    color: $gray-lightest;
    background-color: $brand-danger;
    border-color: $brand-danger;
    a {
      color: $gray-lightest;
    }
    // &::after {
    //   content: "\f057";
    // }
  }
}

.alert-dismissable, .alert-dismissible {
  max-width: 1170px;
  .close {
    top: -1rem;
    right: 1.5rem;
    font-size: 0;
    float: right;
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
    &::before {
      font-family: $font-icon;
      position: absolute;
      top: 1rem;
      font-size: 2.5rem;
      content: "\f057";
    }

  }
}
