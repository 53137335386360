.front .paragraphs-item-acces-directs, .footer-acces-directs {
  width: 100%;
  bottom: 0;
  z-index: 4;
  background-color: $brand-secondary;
  overflow-y: hidden;
  a {
    color: $gray-lightest;
    font-weight: normal;
    &:hover {
      color: $brand-primary;
    }
    span {
      display: block;
      line-height: 1.2;
      padding-top: 0.5rem;
      padding-bottom: 0.8rem;
    }
  }
  // @media only screen and (max-width: 991px) {
  //   display: none;
  // }
  &.affix {
    height: auto;
  }
  &.affix-bottom {
    top: -15px !important;
    height: auto;
  }
  // .view-acces-directs {
  //   padding-bottom: .4rem;
  // }
  .entity-paragraphs-item {
    margin-bottom: 0;
    margin: auto;
  }
  .acc-dir-page .paragraphs-item-acces-directs .field-collection-view {
    border-bottom: 0;
  }

  .field-collection-container {
    max-width: 1140px;
    margin: auto;
    border-bottom: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    .item-acces {
      width: 16.67%;
    }
  }

  // Acces direct bandeau footer
  .views-field-field-mise-en-page {
    .field-collection-container {
      .item-acces:nth-child(4) {
        background-color: #f2f5e9;
        border-radius: 0.8rem;
        margin: 1rem 0;
        position: relative;
        &::before {
          width: 0;
          height: 0;
          color: #f39500;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid;
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .item-acces:nth-child(4) a {
        color: $brand-recherche;
        &:hover {
          color: $brand-tertiary;
        }
      }
    }
  }

  .field-collection-view {
    padding: 0.8rem;
    margin: 0;
    border-bottom: 0 dotted $brand-tertiary;
    text-align: center;
    font-size: .8rem;
    width: 100%;
    @media only screen and (min-width: 1200px) {
      font-size: 1.4rem;
    }
  }

  .fa {
    font-size: 2.5rem;
    @media only screen and (min-width: 1200px) {
      font-size: 3.5rem;
    }
  }
}

  // Acces direct home
.front .paragraphs-item-acces-directs {
  background: $gray-lightest;
  line-height: 2;
  .item-acces:nth-child(4) {
    background-color: #f2f5e9;
    border-top: 8px solid $brand-recherche;
    border-radius: 0.8rem;
    position: relative;
    &::before {
      width: 0;
      height: 0;
      color: #f39500;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  a {
    color: $brand-primary;
    &:hover {
      color: #766f7a;
    }
  }
  .item-acces:nth-child(1) a {
    color: #f39500;
  }
  .item-acces:nth-child(2) a {
    color: #7aa222;
  }
  .item-acces:nth-child(4) a {
    color: $brand-recherche;
    &:hover {
      color: $brand-tertiary;
    }
  }

  .item-acces:nth-child(1) a:hover {
    color: #DB700B;
  }
  .item-acces:nth-child(2) a:hover {
    color: #678A1D;
  }
}