
.region-header-top-middle {
  text-align: center;
  .logo img {
    max-height: 3.9rem;
    max-width: 12rem;
    height: auto;
    width: auto;
  }
}
