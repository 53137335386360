.footer-map {
  position: relative;
  margin-top: 22px;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    font-family: $font-icon;
    content: $fa-var-map-marker-alt;
    font-size: 3rem;
    font-weight: bold;
    color: $brand-primary;
  }
}


.footer-nos-revues {
  strong {
    color: $gray-lightest;
  }
  .txt-revue-footer {
    a {
      color: $gray-lightest;
      text-decoration: none;
      font-weight: 300;
    }
    &:hover {
      strong, a {
        color: $brand-tertiary;
      }
    }
  }
  img {
    width:100%;
    max-width: 6rem;
    float:left;
    margin-right: 1rem;
  }
}
