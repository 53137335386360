// 06 Tables

.table {
  background: $gray-lightest;
  th {
    color: $brand-primary;
  }

  @media screen and (min-width: 768px) {
    &.small-only {
      display:none;
    }
  }
  @media screen and (max-width: 767px) {
    &.large-only {
      display:none;
    }
  }

}
