#main-navigation {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  align-items: center;
}

#sidebar-wrapper {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: $gray-lightest;
  margin-left: -300px;
  z-index: 9999;
  border-right: 1px solid $brand-secondary-cam;
  @media screen and (max-width: 991px) {
    top: 6.25rem;
  }
  @media screen and (min-width: 992px) {
    top: 0;
    width: 250px;
  }
}

.menu {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: $gray-lightest;

  @media screen and (max-width: 991px) {
    top: auto;
  }

  @media screen and (min-width:992px) {
    top: 0;
  }

  @media screen and (min-width: 1920px) {
    width: 100%;
  }

  li {
    position: relative;
    width: 100%;
    display: inline-block;
    a {
      display: block;
      color: $text-color;
      text-decoration: none;
      min-height: 50px;
      padding: 1.5rem 2.5rem;
      box-sizing: border-box;
    }
    > a {
      border-bottom: 1px solid $brand-secondary-cam;
      font-weight: normal;
    }
    &.expanded.open > a {
      font-weight: bold;
      border: 0;
    }
    a:hover,
    a:active,
    a:focus,
    &.open a:hover,
    &.open a:active,
    &.open a:focus {
      text-decoration: none;
      background-color: $brand-secondary-cam;
    }

    &.expanded {
      > a::after {
        font-family: $font-icon;
        content: $fa-var-chevron-right;
        position: absolute;
        right: 1rem;
        font-weight: bold;
      }
    }

    &.open {
      > a::after {
        content: '\f078';
      }
    }
  }

  .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: $brand-secondary-cam;
    box-shadow: none;
    .dropdown-menu {
      background: $brand-secondary-cam;
      li {
        &::before {
          width: 1.5rem;
        }
      }
    }
    > li {
      // display: list-item;
      // list-style-type: disc;
      // list-style-position: inside;
      margin-left: 4rem;
      > a {
        display: inline-block;
        border: 0;
        min-height: 3rem;
        padding: 0;
        white-space: normal;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .active > a {
      background: transparent;
      color: $text-color;
      font-weight: bold;
    }
    .dropdown-submenu.active {
      > .dropdown-menu {
        display: block;
      }
    }
  }

  .caret {
    display: none;
  }
}

.hamburger {
  display: block;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  left: 0;
  @media screen and (max-width: 767px) {
    top: 7px;
  }
  @media screen and (min-width: 768px) {
    top: -10px;
  }
  &::before {
    font-family: $font-icon;
    content: $fa-var-bars;
    font-size: 3rem;
    font-weight: 900;
    color: $brand-primary;
    @media screen and (min-width: 768px) {
      font-size: 3.9rem;
      position: relative;
    }
    @media screen and (min-width: 992px) {
      position: relative;
      left: 40px;
    }
  }
  .menu-text {
    display: none;
  }

  @media screen and (min-width: 992px) {
    position: relative;
    font-size: 4.1rem;
    color: $brand-secondary;
    &:hover {
      color: $brand-secondary;
    }

    .menu-text {
      display: inline-block;
      position: relative;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 0;

      &.m {
        left: -3rem;
      }

      &.nu {
        left: .6rem;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    text-decoration: none;
  }
}