// Bouton recherche
.search-button {
  height: 4.8rem;
  a {
    color: $brand-primary;
    font-size: 2.5rem;
  }
  .btn {
    margin-right: 3.8rem;
  }
}

#search-block-form {
  display: inline-block;
  margin: 0 2rem;
  text-align: right;
  z-index: 30;
  .btn {
    min-width: unset;
    font-size: 0;
    background: transparent;
    color: $brand-primary;
    border: 0;
    padding: .2rem 0;
    &::after {
      font-family: $font-icon;
      content: $fa-var-search;
      font-size: 2.5rem;
      font-weight: 900;
    }
    &:active {
      box-shadow: none;
    }
  }
}

.block-search {
  display: none;
  .btn {
    margin-right: 2rem;
  }
  &.show {
    position: absolute;
    top: 0;
    left: 0;
    height: 4.4rem;
    padding: 0;
    background: $gray-lightest;
    border: 1px solid $brand-secondary-cam;
    color: $brand-secondary-cam;
    @media screen and (max-width: 767px) {
      top: 5px;
    }
    @media screen and (max-width: 1199px) {
      z-index: 10;
      width: 100%;
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      top: 1px;
    }
    @media screen and (min-width: 992px) {
      max-width: 21rem;
    }
    .form-text, .btn {
      &:focus {
        outline: none;
        background: transparent;
        box-shadow: none;
      }
    }
    .form-text {
      border: none;
      width: 14rem;
      border-radius: 0;
      height: 4.3rem;
      background: transparent;
      &::placeholder {
        color: $brand-secondary;
        opacity: 1;
      }
    }
  }
}