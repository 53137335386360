.logged-in {
  .field-collection-view-links, .action-links-field-collection-add {
    display: none;
  }
  .field-collection-container, .field-collection-view {
    border-bottom: 0;
  }

  #sidebar-wrapper, .hamburger {
    @media screen and (min-width: 992px) {
      // top: 100px;
    }
  }
  // .search-button {
  //   @media screen and (min-width: 992px) {
  //     top: 95px;
  //   }
  // }


}
