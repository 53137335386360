.user-menu {
  position: relative;
  padding: 0;
  display: inline-block;
  width: 16rem;
}

.menu-utilisateur {
  // border: 1px solid $brand-secondary-cam;
  border-radius: $btn-border-radius-small;
  box-sizing: border-box;
  &::before {
    position: absolute;
    left: -1rem;
    height: 4.8rem;
    width: 1px;
    margin: -.3rem 0;
    content: '';
    background: $brand-secondary-cam;
  }
  ul {
    min-width: unset;
    > li {
      padding: .7rem 0;
      &:last-child {
        padding-bottom: 10px;
      }
    }
  }
  .username, .img-picture, .no-img-picture {
    display:inline-block;
    vertical-align: middle;
  }
  .username {
    margin-right: 1rem;
    font-size: 1.3rem;
    color: $brand-secondary;
    max-width: 10rem;
    max-height: 3.6rem;
    overflow: hidden;
    // padding-left: 2rem;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    &:hover {
      cursor: pointer;
      opacity: .9;
    }
  }
  .no-img-picture {
    max-height: 45px;
    max-width: 45px;
    margin-left: 1rem;
    a {
      font-size: 0;
      &::before {
        content: $fa-var-user-circle;
        color: $brand-secondary;
        font-family: $font-icon;
        font-size: 4rem;
        margin-left: -1rem;
      }
      @media screen and (min-width: 768px) {
        &::before {
          position: relative;
          display: block;
          font-size: 4.5rem;
          top: -.8rem;
        }
      }
    }
  }
  .img-picture {
    cursor: pointer;
    border-radius : 100%;
    // border: 2px solid $brand-secondary;
    height: 45px;
    width: 45px;
  }
  .menu-arrow {
    display: none;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid $brand-secondary-cam;
    position: absolute;
    margin-top: -2px;
  }
  .dropdown-menu {
    min-width: unset;
    font-size: inherit;
    margin: 16px 0 0 0;
    left: inherit;
    right: 0;
    border: 1px solid $brand-secondary-cam;
    // &::before {
    //   content: "";
    //   width: 0;
    //   height: 0;
    //   border-left: 15px solid transparent;
    //   border-right: 15px solid transparent;
    //   border-bottom: 20px solid $brand-secondary;
    //   position: absolute;
    //   bottom: 100%;
    //   // right: 11.5px; !!!!! Calculé dynamiquement dans main-menu-vertical.js
    // }
    @media screen and (min-width: 1375px) {
      right: inherit;
      // &::before {
      //   right: inherit;
      //   // left: 11.5px; !!!!! Calculé dynamiquement dans main-menu-vertical.js
      // }
    }
  }
}
