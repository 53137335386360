.branding {
  text-align: center;

  > * {
    margin: auto;
  }
}

// @media screen and (min-width: 1200px) {
//   .logo-wrapper {
//     height: 7.555rem;
//   }
// }

.logo {
  // position: relative;
  // padding: 0 0 5px;
  // top: -15px;
  text-align: center;

  @media screen and (max-width: 767px) {
    img {
      max-height: 40px;
    }
  }

  img {
    @media screen and (min-width: 768px) {
      height: auto;
      width: 110px;
    }

    @media screen and (min-width: 1200px) {
      width: 160px;
      padding-bottom: 0.5rem;
    }
  }

  &:hover {
    max-width: 180px;
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
}

#site-name {
  margin: 0;
  font-size: 5rem;
  line-height: 0;

  @media screen and (max-width: 767px) {
    display: none;
  }

  a {
    color: $site-name;
    font-size: 2.2rem;
    line-height: 1.5;
    margin: 10px 0;
    font-weight: 300;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
      filter: alpha(opacity=80);
    }

    @media screen and (min-width: 768px) {
      font-size: 1.3rem;
    }

    @media screen and (min-width: 992px) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 2.1rem;
    }
  }
}
