.formation-ressources-index {

  .fr-diplomes,
  .fr-dpc,
  .fr-toolbox,
  .fr-livreblanc,
  .fr-recommandations {
    margin-top: 10px;
    border-radius: 8px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    max-width: 48%;
    margin-left: 5px;

    @media screen and (min-width: 768px) {
      max-width: 32%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .fr-diplomes a,
  .fr-dpc a,
  .fr-toolbox a,
  .fr-livreblanc a,
  .fr-recommandations a {
    color: $gray-lightest;
    font-size: 1.2rem;
    display: inline-block;
    padding: 10px;
    font-weight: 500;

    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: 992px) {
      font-size: 1.8rem;
    }

    &::before {
      font-family: $font-icon;
      font-size: 1.2rem;
      font-weight: 900;
      width: 20px;
      height: 20px;
      margin-right: 5px;

      @media screen and (min-width: 768px) {
        font-size: 1.6rem;
      }

      @media screen and (min-width: 992px) {
        font-size: 1.8rem;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  .fr-diplomes {
    background-color: $brand-primary;

    @media screen and (min-width: 768px) {
      margin-left: 0;
    }

    a {
      &::before {
        content: $fa-var-graduation-cap;
      }
    }

    &:hover {
      background: $brand-primary-cam;
    }
  }

  .fr-dpc {
    background-color: $brand-secondary;

    @media screen and (min-width: 768px) {
      margin-left: 8px;
    }

    a {
      &::before {
        content: $fa-var-briefcase;
      }
    }

    &:hover {
      background: $brand-secondary-cam;
      color: $text-color;
    }
  }

  .fr-toolbox {
    background-color: $brand-tertiary;

    @media screen and (min-width: 768px) {
      margin-left: 8px;
    }

    a {
      &::before {
        content: $fa-var-wrench;
      }
    }

    &:hover {
      background: $brand-tertiary-cam;
      color: $text-color;
    }
  }

  .fr-livreblanc {
    background-color: $brand-quaternary-cam;

    @media screen and (min-width: 768px) {
      margin-left: 16.67%;
    }

    a {
      &::before {
        content: $fa-var-book;
      }
    }

    &:hover {
      background: $brand-quaternary-cam;
    }
  }

  .fr-recommandations {
    background-color: $brand-primary;

    @media screen and (min-width: 768px) {
      margin-left: 8px;
    }

    a {
      &::before {
        content: $fa-var-comment;
      }
    }

    &:hover {
      background: $brand-primary-cam;
    }
  }
}