#block-views-agenda-block-agenda, .home-agenda-headline {
  h2 {
    color: $brand-primary;
    margin: 0;
    padding: 0;
    font-size: 2rem;
  }
}

.home-agenda-headline {
  .affiche {
    padding: 0;
      margin-bottom: 3rem;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  ul {
    padding: 0;
    li {
      list-style-position: inside;
      list-style-type:none;
      position:relative;
      left: 1.5rem;
      a {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
}

#block-views-agenda-block-agenda, .view-id-agenda {
  .views-row {
    position: relative;
    padding-left: 7rem;
    &:nth-child(odd) {
      clear:left;
    }
    @media screen and (min-width: $screen-sm-min) {
      min-height: 9rem;
    }
    &::before {
      position: absolute;
      left: 0;
      font-family: $font-icon;
      content: $fa-var-calendar;
      font-weight: 400;
      font-size: 6rem;
      margin-top: -1rem;
      color: $brand-primary;
      opacity: 0.4;
    }
    &::after {
      height: 3rem;
      content: '';
      display: block;
    }
    .views-field-field-date-1, .views-field-field-date-2 {
      position: absolute;
      color: $brand-primary;
    }
    .views-field-field-date-1 {
      margin-top: 2rem;
      left: 1.16rem;
      font-size: 2.5rem;
      font-weight: bold;
    }
    .views-field-field-date-2 {
      left: 0;
      margin-top: 6.5rem;
      width: 5.25rem;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
    }
    .views-field-field-event {
      border: 1px solid $brand-secondary-cam;
      color: #54455c;
      padding: .5rem;
      border-radius: 5px;
      font-size: 1.2rem;
    }
    .views-field-field-date {
      color: $brand-secondary;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .views-field-title {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}

#block-views-agenda-block-agenda {
  max-width: 970px;
  margin: 3rem auto 1.5rem auto;
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
  a {
    color: $brand-primary;
  }
  .block-title {
    text-align: left;
    margin-bottom: 3rem;
    color: $brand-primary-cam;
  }
  .views-row {
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      margin-top: 1.5rem;
    }
    .views-field-field-event {
      position: absolute;
      margin-top: .5rem;
    }
    // &:nth-child(odd) {
    //   .views-field-field-event {
    //     margin-right: 1.5rem;
    //   }
    // }
    // &:nth-child(even) {
    //   .views-field-field-event {
    //     margin-left: 1.5rem;
    //   }
    // }
  }
  .more-link {
    bottom: 0;
    position: absolute;
    right: 0;
    .btn, .btn:active {
      color: $brand-primary;
    }
  }
}

.card-body {
  background:$brand-secondary-cam;
  border-radius:1rem;
  padding:1.5rem .5rem;
  margin:1.5rem 0;
  &:hover {
    background:$brand-secondary;
    .fa, .title, .text, a {
      color:$gray-lightest;
      transition: all 1s ease-out;
    }
  }
  p {
    width: auto;
    margin: auto;
    display: inline;
  }
}
.text {
  margin:20px 0px;
}
