// 07 Image alignments

figure {
  display: table;
  margin: auto auto 1rem;
  @media screen and (min-width: 768px) {
    max-width: 50%;
  }
  figcaption {
    display: table-caption;
    max-width: 100%;
    padding-top: 1rem;
    caption-side: bottom;
    font-size: 1.6rem;
    line-height: 1.5;
  }
  img {
    max-width: 100%;
  }
  &.pull-left, &.pull-right  {
    @media screen and (max-width: 767px) {
      float:none !important;
    }
  }
  &.pull-left {
    @media screen and (min-width: 768px) {
      margin-right: 20px;
    }
  }
  &.pull-right {
    @media screen and (min-width: 768px) {
      margin-left: 20px;
    }
  }
  &.pull-center {
    max-width: 100%;
    margin-top: 2rem;
    figcaption {
      display:block;
    }
  }
}
