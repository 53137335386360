.maintenance-page {
  #header {
    img {
      margin: auto;
      padding:15px;
    }
  }
  #container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    border: 1px solid $gray-base;
    padding: 15px;
  }
}
