.node-type-bureau {
  ////// TEMPORAIRE LE TEMPS D'AVOIR LE MOT DU PRESIDENT

  .field-name-field-paragraph
    > .field-items
    > .field-item:nth-child(2)
    .wrapper.bureau {
    padding-bottom: 3rem;
  }
  .bureau {
    .organisation-img.img-big {
      margin: auto !important;
      float: none !important;
      margin-bottom: 3rem !important;
    }
    .social {
      display: none;
    }
  }

  .discours {
    display: none;
  }

  .field-name-field-paragraph > .field-items {
    > .field-item:nth-child(5),
    > .field-item:nth-child(6),
    > .field-item:nth-child(7) {
      background: #54455c;
      margin-top: -15px;
      display: none;
    }
  }

  //////////////////////// FIN

  .col-xs-12 {
    padding-left: 0px;
    padding-right: 0px;

    .page-header {
      padding-left: 30px;
      @media screen and (min-width: 992px) {
        padding-left: 70px;
        margin-bottom: 40px;
      }
    }

    .submitted {
      padding: 0.3rem;
      opacity: 0;
    }
  }

  .entity-paragraphs-item {
    margin: auto;
  }

  .field-name-field-paragraph > .field-items > .field-item:nth-child(1),
  .field-name-field-paragraph > .field-items > .field-item:nth-child(3) {
    background: $brand-secondary-cam;
  }

  .bureau-title {
    max-width: 1170px;
    margin: auto;
    padding: 20px;
    margin-top: -40px;
    color: $brand-primary;

    @media screen and (min-width: 768px) {
      padding-left: 30px;
    }

    @media screen and (min-width: 992px) {
      padding-left: 70px;
    }

    @media screen and (min-width: 1200px) {
      padding-left: 33px;
    }
  }

  .field-name-field-paragraph > .field-items > .field-item:nth-child(2) {
    background: $brand-secondary-cam;

    @media screen and (min-width: 768px) {
      margin-top: -25px;
    }

    .wrapper.bureau {
      max-width: 1170px;
      margin: auto;
    }
  }

  // Gestion card big
  .field-item.odd .img-big.bureau {
    max-width: 100%;

    @media screen and (min-width: 768px) {
      max-width: 40%;
      float: left;
      margin-right: 20px;
      margin-bottom: 10px;
      padding: 10px 0 10px 30px;
    }

    @media screen and (min-width: 992px) {
      padding: 20px 0 10px 75px;
    }

    @media screen and (min-width: 1200px) {
      padding-left: 50px;
      padding-bottom: 0;
    }

    .organisation-item {
      background: $brand-secondary-cam;

      img {
        height: auto;
        width: 100%;
      }

      .informations {
        background: $brand-primary;
        color: $gray-lightest;
        padding: 10px 0 35px 0;
        text-align: center;

        @media screen and (min-width: 768px) {
          padding: 15px 0 35px 0;
        }

        h3 {
          font-size: 1.8rem;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
          color: $gray-lightest;

          @media screen and (min-width: 992px) {
            font-size: 2rem;
          }
        }

        .fonction {
          color: $brand-secondary;
          font-size: 1.6rem;

          @media screen and (min-width: 992px) {
            font-size: 1.8rem;
          }
        }

        .hopital {
          margin-bottom: 10px;
        }

        .social {
          padding: 0 15px;

          a {
            &[href*="facebook"] {
              float: right;
            }

            &[href*="linkedin"] {
              float: left;
            }

            i {
              font-size: 1.8rem;
              padding: 5px;
              color: $gray-lightest;
            }

            &:hover {
              text-decoration: none;

              i {
                color: $brand-secondary;
              }
            }
          }
        }
      }
    }
  }

  //Gestion mot du Président
  .mot-president {
    margin-top: -10px;
    padding: 0px 30px 15px 30px;

    @media screen and (min-width: 768px) {
      padding: 0px 30px 15px 30px;
    }

    @media screen and (min-width: 992px) {
      padding: 5px 75px 60px 15px;
    }

    @media screen and (min-width: 1200px) {
      padding-right: 50px;
      padding-bottom: 80px;
    }

    h3 {
      padding-top: 15px;

      @media screen and (min-width: 768px) {
        padding-top: 10px;
      }

      @media screen and (min-width: 992px) {
        margin-top: 0px;
      }
    }

    p {
      text-align: justify;
    }
  }

  // Gestion des sliders de la page organisation
  .slider-ca,
  .bureau-slider {
    .view-content {
      max-width: 1170px;
      margin: auto;

      .bx-wrapper {
        margin: auto;
        border: none;

        li {
          text-align: center;

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .bx-pager {
        a:hover,
        a:focus {
          text-decoration: none;
        }
      }
    }
  }
}

.bx-viewport {
  padding-left: 8px;
  padding-right: 8px;
  @media screen and (min-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.bureau-slider {
  background: $brand-secondary-cam;
  padding-bottom: 10px;
  margin-top: -25px;
  padding-left: 20px;
  padding-right: 15px;

  @media screen and (min-width: 768px) {
    padding-bottom: 40px;
  }

  .slider-bureau {
    li {
      background: $brand-primary;
      height: 315px;
      &::before {
        display: none;
      }

      .nom-bureau,
      .hopital-bureau {
        color: $gray-lightest;
      }

      .nom-bureau {
        padding: 5px;
        font-weight: bold;

        @media screen and (min-width: 768px) {
          padding: 5px;
        }
      }

      .fonction-bureau {
        color: $brand-secondary;
      }

      .hopital-bureau {
        padding: 5px 5px 15px 5px;
        font-size: 1.1rem;

        @media screen and (min-width: 768px) {
          padding: 0 5px 15px 5px;
        }

        @media screen and (min-width: 1200px) {
          font-size: 1.3rem;
        }
      }
    }
  }

  .bx-has-pager {
    text-align: center;
    padding-top: 10px;

    .bx-pager-item {
      display: inline-block;

      a {
        font-size: 0;

        &::before {
          font-family: $font-icon;
          content: $fa-var-circle;
          color: transparent;
          border: 3px solid $brand-secondary;
          border-radius: 50%;
          font-size: 1.8rem;
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }

      .active {
        &::before {
          font-family: $font-icon;
          content: $fa-var-circle;
          color: $brand-secondary;
          border: none;
          font-size: 2rem;
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}

// Gestion slider conseil adminsitration
.slider-ca {
  max-width: 1170px;
  margin: auto;
  background: $gray-lightest;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 15px;

  h2 {
    text-align: center;
    color: $brand-primary;

    @media screen and (min-width: 992px) {
      margin-bottom: 30px;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 50px;
    }
  }

  .bx-viewport {
    @media screen and (min-width: 768px) and (max-width: 992px) {
      margin: auto;
    }
  }

  .slider-conseil {
    padding-left: 0;

    li {
      background: $brand-secondary;
      height: 280px;
      &::before {
        display: none;
      }
      .nom-ca {
        color: $brand-primary;
        font-weight: bold;
        padding: 5px 5px 0px 5px;
      }

      .hopital-ca {
        color: $gray-lightest;
        padding: 0.5rem 0.5rem 1.5rem 0.5rem;
      }

      &.views-row-odd {
        margin-bottom: 10px;
      }
    }
  }

  .bx-has-pager {
    text-align: center;
    padding-top: 10px;

    .bx-pager-item {
      display: inline-block;

      a {
        font-size: 0;

        &::before {
          font-family: $font-icon;
          content: $fa-var-circle;
          color: transparent;
          border: 3px solid $brand-secondary;
          border-radius: 50%;
          font-size: 1.8rem;
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .active {
        &::before {
          font-family: $font-icon;
          content: $fa-var-circle;
          color: $brand-secondary;
          border: none;
          font-size: 2rem;
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}

//Gestion direction administrative
.field-name-field-paragraph > .field-items > .field-item:nth-child(5) {
  background: $brand-primary;
}

.direction_admin {
  text-align: center;
  background-color: $brand-primary;
  color: $gray-lightest;
  padding-top: 15px;
  padding-bottom: 25px;

  @media screen and (min-width: 992px) {
    padding-top: 30px;
  }

  @media screen and (min-width: 1200px) {
    padding-bottom: 60px;
  }
}

.field-name-field-paragraph > .field-items > .field-item:nth-child(6) {
  background: $brand-primary;
  margin-top: -15px;

  .paragraphs-item-container {
    max-width: 1170px;
    margin: auto;

    .wrapper.direction_administrative {
      margin-top: -15px;
      padding-bottom: 30px;

      @media screen and (min-width: 768px) {
        float: left;
        padding: 0px 10px 10px 10px;
      }

      @media screen and (min-width: 992px) {
        padding: 0px 50px 10px 53px;
      }

      figure {
        margin: auto;

        @media screen and (min-width: 768px) {
          padding: 10px 6px 10px 15px;
          margin-right: 10px;
          max-width: 30%;
          float: left;
        }

        @media screen and (min-width: 992px) {
          padding-left: 0px;
        }
      }

      .informations {
        background: $brand-secondary;
        text-align: center;
        margin-top: -15px;
        padding: 5px 10px 10px 10px;

        @media screen and (min-width: 768px) {
          height: 75px;
        }

        @media screen and (min-width: 992px) {
          height: 80px;
        }

        h3 {
          color: $brand-primary;
          padding: 2px 0px;
          font-size: 1.3rem;
          font-weight: bold;
          margin-bottom: 5px;
          @media screen and (min-width: 992px) {
            font-size: 1.6rem;
          }
        }

        .fonction {
          color: $gray-lightest;
        }

        .hopital {
          display: none;
        }
      }

      .discours {
        color: $gray-lightest;
        padding: 10px 30px;
        text-align: justify;
      }
    }
  }
}

.field-name-field-paragraph > .field-items > .field-item:nth-child(7) {
  background: $brand-primary;

  @media screen and (min-width: 768px) {
    padding-left: 25px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 50px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 100px;
  }

  .paragraphs-item-container {
    max-width: 1170px;
    margin: auto;

    @media screen and (min-width: 768px) {
      margin-top: -15px;
    }

    .field-type-paragraphs {
      .field-items {
        background-color: $brand-primary;
        padding-bottom: 440px;
        margin-top: -35px;

        .paragraphs-item-image-gauche {
          clear: none;
          max-width: 50%;
          float: left;
          margin-bottom: 5px;

          @media screen and (min-width: 768px) {
            max-width: 33%;
            margin-right: 85px;
          }

          @media screen and (min-width: 992px) {
            margin-right: 180px;
          }

          @media screen and (min-width: 1200px) {
            margin-right: 25rem;
          }

          figure {
            margin: auto;
            float: none !important;
            max-width: 80%;

            @media screen and (min-width: 768px) {
              max-width: 100%;
            }

            img {
              max-width: 100%;
            }
          }

          .da-secretaire {
            background-color: $brand-secondary;
            margin-bottom: 1rem;
            float: none;
            clear: both;
            bottom: 2.5rem;
            margin: auto;
            width: 80%;
            text-align: center;
            min-height: 7rem;
            padding-bottom: 0.6rem;

            @media screen and (min-width: 768px) {
              width: 100%;
            }

            h3 {
              color: $brand-primary;
              padding: 5px 0;
              font-size: 1.3rem;
              font-weight: 800;
              margin-bottom: 0;
            }

            span {
              color: $gray-lightest;
            }
          }
        }

        .field-item:nth-child(3) .paragraphs-item-image-gauche {
          margin-right: 0;
        }
      }
    }
  }
}
.footer {
  margin-top: 0;
}
