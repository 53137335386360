.webform-confirmation {
  margin-bottom: 3rem;
}

#content-wrapper {
  .links a {
    text-decoration: none;
    &:hover {
      color: $brand-secondary-cam;
    }
  }
}

#node-3, #node-8, #node-234 {
  .panel-default {
    padding-right: 20%;
  }
  
  ::placeholder {
    color: $brand-secondary;
    font-weight: normal;
  }

  label {
    display: block;
    font-size: 1.2rem;
    font-weight: normal;
  }
  
  .label-text {
    font-size: 1.4rem;
  }

  strong {
    color: $brand-secondary;
  }

  li::before {
    content: '';
  }

  .empty-line {
    height: 0;
    padding: 0;
    margin: 0;
  }

  .form-control,
  .select2-container--default .select2-selection--multiple {
    border: 1px solid $brand-secondary-cam;
  }

  .select2-container--default .select2-selection--multiple {
    height: 40px;
  }

  .webform-component, #selected_result .form-item {
    margin-right: 1.5%;
  }

  // Nom

  .webform-component--nom-prenom-mail--lien {
    margin-bottom: 3rem;
  }

  .sso-firstname, .sso-lastname {
    font-weight: bold;
  }

  // La première partie du formulaire d'adhésion : Identité

  .dropdown-menu > li > a {
    overflow: hidden;
  }

  // Vous êtes :

  #edit-submitted-civicrm-1-case-1-cg12-custom-21 {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content:  space-between;
    .label-text {
      font-weight: bold;
      font-size: 1.6rem;
      color: $brand-primary;
    }
  }

  .form-item-submitted-civicrm-1-case-1-cg12-custom-21.radio {
    padding: 2rem;
    display: block;
    border: 1px solid $brand-secondary-cam;
    width: 49%;
    margin-top: 0;
    margin-bottom: 2rem;
    box-sizing: border-box;
    &.selected {
      border-width: 2px;
      border-color: $brand-secondary;
    }
  }

  .vignette-mbs {
    margin-top: 1.5rem;
  }

  #titulaire span, #en-formation span, #non-rhumatologue span, #etranger span, #francophone-en-formation span {
    color: $brand-tertiary;
    font-weight:bold;
    padding-top: 1.5rem;
  }

  // Informations personnelles

  // .webform-component--civicrm-1-contact-1-contact-prefix-id,
  // .webform-component--civicrm-1-contact-1-contact-suffix-id {
  //   display: inline-block;
  // }
  
  // .webform-component--civicrm-1-contact-1-contact-prefix-id {
  //   width: 8%;
  // }

  // .webform-component--civicrm-1-contact-1-contact-suffix-id {
  //   width: 15%;
  // }
  
  .webform-component--civicrm-1-contact-1-contact-existing {
    clear: both;
    margin: 4rem;
  }

  // Date de naissance
  .webform-component--civicrm-1-contact-1-contact-birth-date {
    display: inline-block;
    margin-bottom: 0%;
    .form-control {
      border: 0;
    }
  }
  .webform-datepicker {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: $btn-border-radius-small;
    > .form-type-select {
      display: inline-block;
      border: none;
      margin-bottom: 0;
      .form-control:focus {
        box-shadow: none;
      }
      &:nth-child(1), &:nth-child(2) {
        &::after {
          // font-family: $font-icon;
          content: "/";
          font-weight: normal;
        }
      }
      &:nth-child(3) {
        &::after {
          content: "";
        }
      }
    }
  }

  .form-item-submitted-civicrm-1-contact-1-contact-birth-date-day {
    width: 70px;
  }
  .form-item-submitted-civicrm-1-contact-1-contact-birth-date-month {
    width: 110px;
  }
  .form-item-submitted-civicrm-1-contact-1-contact-birth-date-year {
    width: 85px;
  }
  .webform-calendar {
    padding: .4em;
    &:focus {
      outline: 0;
    }
    a::before {
      font-family: $font-icon;
      content : $fa-var-calendar;
      color: $brand-warning;
      font-size: 3rem;
      float: left;
      padding-right: 4rem;
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }

  // Nationalité

  .wrap-webform-adhesion-nationalite {
    // display: inline-block;
    max-width:180px;
  }

  // Téléphone

  .mobile-number-field {
    display: inline-block;
    margin-right: 1.5rem;
    border: 0;
    .form-type-select {
      padding: 0;
      border: 0;
      &::after {
        background: transparent;
        content: '';
      }
    }
    .form-type-textfield input {
      width: 170px;
      border-radius: 0;
    }
  }

  .webform-component--civicrm-1-contact-1-cg1-custom-12 {
    display: inline-block;
  }

  // #edit-submitted-civicrm-1-contact-2-phone-phone, #edit-submitted-civicrm-1-contact-1-phone-phone {
  //   width: 100%;
  // }
  
  // #edit-submitted-civicrm-1-contact-2-phone-phone {
  //   float: left;
  //   clear: left;
  // }

  // .form-item-submitted-civicrm-1-contact-1-phone-phone-country-code,
  // .form-item-submitted-civicrm-1-contact-2-phone-phone-country-code {
  //   border: 0;
  //   &::after {
  //     display: none;
  //   }
  // }
  #card-type-membre {
    p {
      background: $brand-primary;
      padding: 10px;
    }
  }

  // Adresse

  .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-city,
  .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-country-id,
  .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-postal-code,
  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-city,
  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-country-id,
  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-postal-code,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-city,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-country,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-postal-code,
  .webform-component--adresse--civicrm-1-contact-1-address-postal-code,
  .webform-component--adresse--civicrm-1-contact-1-address-country-id,
  .webform-component--adresse--civicrm-1-contact-1-address-city,
  .form-item-submitted-adresse-professionnelle-petite-structure-pro-civicrm-1-contact-2-address-postal-code-suffix {
    display: inline-block;
  }

  // Adresse personnelle

  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-street-address,
  .webform-component--adresse--civicrm-1-contact-1-address-street-address,
  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-supplemental-address-1,
  .webform-component--adresse--civicrm-1-contact-1-address-supplemental-address-1 {
    width: 48%;
    display: inline-block;
  }

  // Ville, pays

  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-city,
  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-country-id,
  .webform-component--adresse--civicrm-1-contact-1-address-city,
  .webform-component--adresse--civicrm-1-contact-1-address-country-id {
    width: 42%;
  }

  // Code postal

  .webform-component--adresse-personnelle--civicrm-1-contact-1-address-postal-code,
  .webform-component--adresse--civicrm-1-contact-1-address-postal-code {
    width: 11%;
  }

  // Adresse professionnelle

  // Ville, pays

  .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-city,
  .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-country-id,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-city,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-country {
    width: 35.5%;
  }

  // Code postal

  .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-postal-code,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-postal-code {
    width: 80px;
  }

  // Cedex

  .form-item-submitted-adresse-professionnelle-petite-structure-pro-civicrm-1-contact-2-address-postal-code-suffix {
    width: 80px;
  }

  #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-19-remove-button,
  #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg13-custom-22-remove-button {
    background-color: $brand-tertiary;
    border: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-complement-custom-9 {
    width: 42%;
  }

  .form-item-submitted-adresse-professionnelle-type-de-structure-professionnelle,
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle,
  .form-item-submitted-address-preferences-adresse-principale,
  .form-item-submitted-address-preferences-adresse-de-facturation {
    > label {
      margin-top: 1.5rem;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  // Recherche adresse professionnelle

  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle {
    .form-disabled {
      margin-bottom: 0;
      display: block;
      > label {
        display: none;
      }
    }
    input[disabled], input[readonly] {
      border: 0;
      background: transparent;
      height: auto;
      padding: 0;
      width: auto;
      cursor: default;
      &:hover, &:focus {
        cursor: text;
        box-shadow: 0;
      }
    }

    .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-address-name {
      font-weight: bold;
    }

    .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-postal-code {
      float: left;
      width: 42px;
      margin-top: 3px;
      margin-right: 2px;
    }

    .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-selected-result-city {
      display: inline-block;
    }

    #complement {
      margin-top: 1.5rem;
    }

    .webform-component--informations-professionnelles--civicrm-1-contact-1-cg1-custom-2,
    .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-16 {
      width: 42%;
    }

  }

  // Informations professionnelles

  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-19,
  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg13-custom-22 {
    margin-bottom: 1.5rem;
    .help-block a[data-toggle*="popover"]  {
      display: none;
    }
  }

  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg1-custom-2 {
    width: 118px;
  }

  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-7 {
    width: 40%;
  }

  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-3,
  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg1-custom-4,
  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg1-custom-27 {
    display: inline-block;
  }

  .webform-component--informations-professionnelles--civicrm-1-contact-1-cg1-custom-4 {
    display: inline-block;
    width: 100px;
  }

  // RGPD

  // .webform-component--lien-page-rgpd a {
  //   color: $brand-tertiary;
  // }

  .webform-component--rgpd {
    margin-top: 3rem;
  }

  .webform-component--civicrm-1-contact-1-cg1-custom-2 {
    margin-top: 4%;
    .help-block a {
      text-decoration: none;
      color: $brand-tertiary;
    }
  }
  .form-item-submitted-civicrm-1-contact-1-phone-phone-mobile {
    display: inline-block;
  }
  .webform-component--civicrm-1-contact-1-contact-prefix-id {
    display: inline-block;
    width: 76px;
  }
  .webform-component--civicrm-1-contact-1-contact-suffix-id,
  .webform-component--civicrm-1-contact-1-contact-last-name {
    display: inline-block;
    width: 112px;
  }
  .webform-component--civicrm-1-contact-1-contact-first-name {
    display: inline-block;
    width: 49%;
  }

  // Transfert de fichier

  .form-type-managed-file {
    .form-managed-file {
      display: block;
    }
  }
}

.form-actions {
  .webform-submit {
    float:none;
  }
}

@media screen and (min-width: 768px) {
  .webform-component--civicrm-1-contact-1-cg1-custom-2 input {
    width: 30%;
    margin-top: 2%;
  }
  .webform-progressbar {
    width: 33.33%;
    .webform-progressbar-outer {
      margin-top: 7%;
    }
  }
  #card-type-membre {
    width: 100%;
  }

  .form-item-submitted-civicrm-1-case-1-cg12-custom-21 {
    clear: both;
    .control-label {
      .civicrm-enabled::before {
        margin-right: 10px;
        color: #522a5b;
      }
      .civicrm-enable {
        color: #522a5b;
      }
    }
  }
  #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-19-ajax-wrapper {
    margin-top: 3%;
  }
  // #edit-submitted-civicrm-1-contact-1-phone-phone,
  // #edit-submitted-civicrm-1-contact-2-phone-phone {
  //   width: 100%;
  // }
}
// @media screen and (min-width: 992px) {
//   .webform-calendar {
//     margin-top: 1%;
//   }
//   .help-block {
//     width: 100%;
//   }
//   #edit-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-complement-custom-9 {
//     width: 98%;
//   }
//   .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-postal-code {
//     width: 18%;
//     display: inline-block;
//   }
//   .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-city {
//     width: 40%;
//     display: inline-block;
//     // margin-left: 2%;
//   }
//   .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-country-id {
//     width: 38%;
//     display: inline-block;
//     // margin-left: 2%;
//   }
// }

@media screen and (min-width: 1200px) {
  .webform-component-fieldset {
    width: 100%;
  }
  .webform-calendar {
    margin-top: 1%;
  }
  .form-item-submitted-civicrm-1-case-1-cg12-custom-21 .control-label {
    margin-left:0%;
  }
  #avantages {
    margin-left: 72%;
    width: 316px;
    height: 370px;
    max-width: 370px;
  }
  // #edit-submitted-civicrm-1-case-1-cg12-custom-21 {
  //   margin-left: -3%;
  // }
  // .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-postal-code {
  //   width: 11%;
  //   display: inline-block;
  // }
  // .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-city {
  //   width: 42%;
  //   display: inline-block;
  //   // margin-left: 2%;
  // }
  // .webform-component--adresse-professionnelle--petite-structure-pro--civicrm-1-contact-2-address-country-id {
  //   width: 42%;
  //   display: inline-block;
  //   // margin-left: 2%;
  // }

  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-contact-search {
    width: 50%;
  }
}

/************************************************** La dexième partie du formulaire d'adhésion : Informations **********************************************************/
@media screen and (min-width: 320px) {
  #bootstrap-panel--6 {
    .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-contact-search {
      .control-label {
        font-weight: normal;
      }
    }
  }
  #bootstrap-panel-2-body {
    .webform-component--adresse-professionnelle--civicrm-2-contact-1-fieldset-fieldset--recherche-adresse-professionnelle {
      .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-professionnelle-contact-search {
        label {
          font-weight: none;
        }
      }
    }
  }
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-contact-search {
    .input-group-addon:last-child {
      display: none;
    }
  }
  #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-18 {
    display: inline-block;
    .form-item-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-18-1,
    .form-item-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-18-2 {
      display: inline-block;
    }
    .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-18 label {
      padding-right: 3%;
    }
  }

  #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-19-ajax-wrapper .help-block a,
  #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg13-custom-22-ajax-wrapper .help-block a {
    color: $brand-tertiary;
    text-decoration: none;
    &:hover {
      color: $brand-tertiary;
    }
  }
  #reset, #reset-part2 {
    border: none;
    background: $gray-lightest;
    float: right;
  }
}
@media screen and (max-width: 414px) {
  #crm-container.crm-public {
    .content {
      margin-left: 31%;
    }
  }
}
@media screen and (min-width: 768px) {
  // #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-19-ajax-wrapper button,
  // #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg13-custom-22-ajax-wrapper button {
  //   margin-left: 2%;
  // }
  // #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg2-custom-19-upload-button,
  // #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg13-custom-22-upload-button {
  //   margin-top: 0%;
  // }
  // .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-postal-code {
  //   display: inline-block;
  //   width: 20%;
  // }
  // .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-city {
  //   display: inline-block;
  //   width: 37%;
  //   // margin-left: 2%;
  // }
  // .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-country {
  //   display: inline-block;
  //   width: 39%;
  //   // margin-left: 2%;

  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-street-address {
  //   display: inline-block;
  //   width: 49%;
  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-supplemental-address-1 {
  //   display: inline-block;
  //   width: 49%;
  //   // margin-left: 2%;
  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-city {
  //   width: 49%;
  //   display: inline-block;
  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-postal-code {
  //   width: 15%;
  //   display: inline-block;
  //   // margin-left: 2%;
  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-country-id {
  //   width: 32%;
  //   display: inline-block;
  //   // margin-left: 2%;
  // }
  // .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-3 {
  //   width: 65%;
  //   display: inline-block;
  // }
  // .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-7 {
  //   width: 33%;
  //   display: inline-block;
  //   // margin-left: 2%;
  // }
  // #edit-submitted-informations-professionnelles-civicrm-1-contact-1-cg13-custom-22-ajax-wrapper {
  //   .form-control {
  //     width: auto;
  //   }
  // }
  // .webform-component--informations-professionnelles--civicrm-1-contact-1-cg1-custom-4 {
  //   width: 40%;
  //   display: inline-block;
  //   .help-block {
  //     position: absolute;
  //   }
  // }
  // .webform-component--informations-professionnelles--civicrm-1-contact-1-cg2-custom-18 {
  //   display: inline-block;
  //   // margin-left: 4%;
  // }

  // Pager

  .form-actions {
    margin-top: 3rem;
    width: 100%;
    text-align: center;
    input {
      margin: auto;
    }
  }

}
@media screen and (min-width: 992px) {
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-complement-custom-9 {
    width: 102%;
  }

  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-city {
    display: inline-block;
    width: 33%;
    // margin-left: 2%;
  }
  // .webform-component--adresse--civicrm-1-contact-1-address-street-address {
  //   display: inline-block;
  //   width: 49%;
  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-supplemental-address-1 {
  //   display: inline-block;
  //   width: 49%;
  //   // margin-left: 2%;
  // }
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-country {
    width: 43%;
  }
  #avantages {
    margin-left: 73%;
    width: 272px;
  }
  #bootstrap-panel-2-body {
    .input-group {
      width: 98%;
    }
  }
}
@media screen and (min-width: 1200px) {
  #avantages {
    margin-left: 69%;
    width: 325px;
    height: 388px;
    max-height: 418px;
  }
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-complement-custom-9 {
    width: 100%;
  }
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-postal-code {
    display: inline-block;
    width: 20%;
  }
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-city {
    display: inline-block;
    width: 33%;
    // margin-left: 2%;
  }
  .form-item-submitted-adresse-professionnelle-civicrm-2-contact-1-fieldset-fieldset-recherche-adresse-pro-selected-result-country {
    display: inline-block;
    width: 43%;
  }
  // .webform-component--adresse--civicrm-1-contact-1-address-street-address {
  //   display: inline-block;
  //   width: 49%;
  // }
  // .webform-component--adresse--civicrm-1-contact-1-address-supplemental-address-1 {
  //   display: inline-block;
  //   width: 49%;
  //   // margin-left: 2%;
  // }
}

.webform-component--bouton-annuler,
.webform-component--bouton-annuler-part2 {
  border: none;
  float: right;
  background: $gray-lightest;
}
  

// Surcharche de l'image de chargement
.autocomplete-throbber {
  /* stylelint-disable-next-line declaration-no-important */
  background: none !important
}
//Charte éthique style
.webform-component--lien-page-charte-ethique{
  padding: 2rem;
  background: #FFF0F9;
  margin: 0;
}
.webform-component--civicrm-1-contact-1-cg1-custom-145,.webform-component--civicrm-1-contact-1-cg1-custom-146,.webform-component--civicrm-1-contact-1-cg1-custom-149{
  padding: 1rem;
  background: #FFF0F9;
}

.webform-component--civicrm-1-contact-1-fieldset-fieldset{
  display: none;
}
.control-label[for="edit-submitted-civicrm-1-contact-1-cg1-fieldset-civicrm-1-contact-1-cg1-custom-145"],
.control-label[for="edit-submitted-civicrm-1-contact-1-cg1-fieldset-civicrm-1-contact-1-cg1-custom-149--2"],

.control-label[for="edit-submitted-civicrm-1-contact-1-cg1-fieldset-lien-page-charte-ethique--2"],
.control-label[for="edit-submitted-civicrm-1-contact-1-cg1-fieldset-civicrm-1-contact-1-cg1-custom-145--2"]{
  display: none;
}
//hack masquer la  boite d'alert sur la page edit charte TODO: trouver d'ou viens l'alert 
.page-user-edit-charte{
  .alert .alert-block .alert-dismissible .alert-success .messages .status{
    display: none;
  }
}

.webform-component--civicrm-1-activity-1-activity-activity-date-time,.webform-component--civicrm-1-activity-1-activity-activity-date-time-timepart{
  display: none;
}

