//== Import Bootstrap styles
// Les différentes variables de Bootstrap sont consultables ici :
// https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss
// Vous pouvez les changer leur valeur en les recopiant dans un des fichiers de variables ci-dessus (vous pouvez en ajouter si nécessaire).
// Core variables and mixins
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
//== Import Template styles
@import "base/01-colors-variables";
// Reset and dependencies
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";
// Core CSS
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";
// Components
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels';
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges';
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron';
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars';
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells';
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";
// Components JavaScript
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel';
// Utility classes
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

//== Bootstrap override
//

@import "_overrides.scss";

//== Base
//
// Il s’agit des éléments par défaut tels qu’ils sont définis sur l’ensemble du site. Ce sont les règles de style “de base” (sans blague).
//
// Bien généralement il s’agit des éléments HTML basiques.
// Mais on peut également y retrouver des sélecteurs descendants (h1 em), des sélecteurs d’enfants (ul > li) ou même des pseudo-classes (a:hover).
//
// Évitez d'utiliser class, id et !important dans cette catégorie.

@import "base/_02-typography";
@import "base/_03-iconography";
@import "base/_04-buttons";
@import "base/_05-form-elements";
@import "base/_06-tables";
@import "base/_07-image-alignments";
@import "base/_08-ui-elements";
@import "base/ckeditor-styles";
@import "base/medias";
@import "base/message-status";

//== Layout
//
// Il s’agit du découpage de l’architecture de votre site en sections principales.
//
// Généralement, il s’agit de sélecteurs simples, descendants ou d’enfants, voire de classes ou d’identifiants (pour le coup, c’est bien le seul endroit où l’usage d’ID est tolérable).
@import "layout/body";
@import "layout/footer";
@import "layout/header";
@import "layout/home";
//== Components
//
// Un component est une section mineure, un composant autonome de la page.
//
// Partir sur une structure modulaire est une bonne pratique à appliquer pour créer et maintenir un CSS flexible et réutilisable. A titre d’exemple, les modules les plus classiques sont certainement la navigation et les boutons.
//
// Évitez d'utiliser id ou !important dans cette catégorie.
@import "components/acces-directs";
@import "components/actualites";
@import "components/annonces";
@import "components/back-to-top";
@import "components/bandeau-sites";
@import "components/cards-cohortes";
// @import 'components/block-user-login';
@import "components/block-convertir";
@import "components/block-adherer";
@import "components/_block-avantages-adhesion.scss";
@import "components/branding";
@import "components/breadcrumb";
@import "components/_civicrm.scss";
// @import 'components/footer-carte';
@import "components/faq";
@import "components/footer-nos-revues";
// @import 'components/footer-nos-sites';
@import "components/home";
@import "components/home-acces-directs";
@import "components/home-actus";
@import "components/home-agenda";
@import "components/home-devenir-membre.scss";
@import "components/home-tiles";
// @import 'components/main-menu';
@import "components/main-menu-vertical";
@import "components/maintenance-page";
@import "components/gallery-images";
@import "components/page-agenda";
@import "components/page-app-mobile";
@import "components/page-organisation";
@import "components/page-perso-profil-public.scss";
@import "components/page-perso-mon-adhesion.scss";
@import "components/page-perso-mes-inscriptions.scss";
@import "components/page-perso-mes-infos.scss";
@import "components/page-recherche-et-innovation.scss";
@import "components/popup-modale.scss";
@import "components/gestion-compte.scss";
@import "components/contact.scss";
@import "components/formations-ressources.scss";
@import "components/eular.scss";
@import "components/card-recipiendaires-bourses.scss";
@import "components/footer-copyright.scss";
// @import 'components/pager';
// @import 'components/paragraphs';
@import "components/search-block";
@import "components/share-links";
@import "components/page-conseil-scientifique";
@import "components/tarteaucitron";
@import "components/taxonomy-term-reference";
// @import 'components/timeline';
@import "components/user-menu";
@import "components/vue-agenda";
@import "components/vue-comite";
@import "components/vues";
@import "components/webform-inscription-presse";

// Il s’agit de classes qui viennent définir un état particulier dans lequel se trouvent vos éléments.
//
// Généralement, une règle qui se trouve dans cette catégorie correspond à une simple classe CSS.
//
// C’est la seule catégorie où l’usage de !important peut être toléré, voire recommandé.
//
// En effet, ce genre de règles déterminent un état final du design, qui doit s’appliquer quoiqu’il advienne. Si votre code est propre, vous ne devriez pas avoir deux états s’appliquant sur le même élément et modifiant les mêmes attributs (sinon, vous avez un petit soucis de logique).
@import "components/form-adhesion";
@import "components/form-renouvellement";
@import "components/form-generique";
@import "state/affix";
@import "state/logged-in";
@import "state/main-menu-vertical-open";

// Propriétés placées ici pour éviter le décallage du sourcemap :
// Issue : https://github.com/sass/libsass/issues/1783

.submitted::before {
  content: $fa-var-clock;
}
