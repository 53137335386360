.front {
  h2 {
    font-weight: 300;
    text-transform: none;
  }
  .region-content {
    h2 {
      text-align: center;
    }
  }
.more-link {
    float: right;
    margin-bottom: .5rem;
    .btn, .btn:active {
      position: relative;
      left: -3rem;
      background:transparent;
      border: 0;
      font-weight: bold;
      line-height: 2;
      padding: 0;
      min-width: initial;
      &:hover {
        opacity: .7;
      }
      &::after {
        border-width: 2px;
        border-style: solid;
        border-radius: 100%;
        position: absolute;
        right: -3rem;
        top: .75rem;
        font-family: $font-icon;
        content: $fa-var-angle-right;
        // font-weight: bold;
        font-size: 2rem;
        line-height: 1;
        height: 24px;
        width: 24px;
      }
    }
  }
}
