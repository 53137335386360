@media screen and (min-width: 768px) {
  form {
    .fg-champ-50pc, .fg-champ-medium, .fg-champ-small {
      margin-right: 25px;
      display: inline-block;
    }
    .fg-champ-small {
      width: 100px;
    }
    
    .fg-champ-medium {
      width: 32%;
    }
    
    .fg-champ-50pc {
      width: 47%;
    }
    .fg-champ-small-1ligne {
      input, select, button, p {
        width: 90px;
      }
    }
    .fg-champ-medium-1ligne {
      input, select, button, p {
        width: 33%;
      }
    }
    .fg-champ-50pc-1ligne {
      input, select, button, p {
        width: 47%;
      }
    }
  }
}

.fg-fieldset {
  border: 0;
  box-shadow:none;
  .panel-heading {
    padding:20px 0;
    background:none;
    .panel-title {
      color: #b7aca1;
    }
  }
  .panel-body {
    padding:0;
  }
  
  label, .mobile-number-field label{
    font-weight:inherit;
  }
  
  .mobile-number-field {
    display:inline-block;
    @media screen and (min-width: 768px) {
      width:47%;
      margin-right: 25px;
    }
    margin-bottom:15px;
    
    label {
      display:block;
    }
    .form-type-textfield {
      input{
        min-width: 300px;
      }
    }
  }
  .form-type-select:not([class*="webform-"])::after {
    display: none;
  }
}

.fg-nobold {
  label {
    font-weight:inherit;
  }
}

.webform-grants{
  .form-managed-file.input-group{
    display: block;
  }
}

#webform-client-form-337 {
  .webform-component--informations-complementaires--informations-coupons-sncf {
    padding: 15px;
  }
  .webform-component--informations-complementaires--informations-coupons-sncf--adresse-dexpeditions-des-coupons-sncf {
    border: none;
    box-shadow: none;
  }
}