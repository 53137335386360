// 04 Boutons

.btn {
  min-width: 14rem;
  font-size: 1.8rem;
  font-weight: 300;
  min-height: 4rem;
  a {
    font-weight: 300;
  }
}

a.btn {
  font-weight: 300;
}

.btn-lg {
  min-height: 6rem;
  min-width: 22rem;
  font-size: 2.4rem;
  line-height: 1.5;
}

.btn-sm {
  min-height: 3.5rem;
  min-width: 10rem;
  font-size: 1.4rem;
}

.btn-xs {
  min-height: 3rem;
  min-width: 8rem;
  font-size: 1.2rem;
}

.btn-default {
  @include button-variant($gray-lightest, $brand-secondary, $brand-secondary);
  &:active, &.active {
    @include button-variant($gray-lightest, $brand-primary, $brand-primary);
  }
  &:hover {
    @include button-variant($text-color,  $brand-secondary-cam, $brand-secondary-cam);
    a {
      color: $gray-lightest;
    }
  }
  a {
    color: $gray-lightest;
    font-size: 1.6rem;
    text-decoration: none;
  }
}

.btn-default-cam {
  @include button-variant($text-color,  $brand-secondary-cam, $brand-secondary-cam);
  &:active, &.active {
    @include button-variant($gray-lightest, $brand-primary, $brand-primary);
  }
  &:hover {
    @include button-variant($gray-lightest, $brand-secondary, $brand-secondary);
    a {
      color: $gray-lightest;
    }
  }
  a {
    color: $text-color;
    font-size: 1.6rem;
    text-decoration: none;
  }
}

.btn-primary {
  @include button-variant($gray-lightest, $brand-primary, $brand-primary);
  &:active, &.active {
    @include button-variant($gray-lightest, $brand-secondary, $brand-secondary);
  }
  &:hover {
    @include button-variant($text-color,  $brand-secondary-cam, $brand-secondary-cam);
    a {
      color: $gray-lightest;
    }
  }
  a {
    color: $gray-lightest;
    font-size: 1.6rem;
    text-decoration: none;
  }
}
.btn-secondary {
  @include button-variant($gray-lightest, $brand-primary-cam, $brand-primary-cam);
  &:active, &.active {
    @include button-variant($gray-lightest, $brand-secondary, $brand-secondary);
  }
  &:hover {
    @include button-variant($text-color,  $brand-secondary-cam, $brand-secondary-cam);
    a {
      color: $gray-lightest;
    }
  }
  a {
    color: $gray-lightest;
    font-size: 1.6rem;
    text-decoration: none;
  }
}

.btn-tertiary {
  @include button-variant($gray-lightest, $brand-tertiary, $brand-tertiary);
  &:active, &.active {
    @include button-variant($gray-lightest, $brand-tertiary-cam, $brand-tertiary-cam);
  }
  &:hover {
    @include button-variant($gray-lightest,  $brand-tertiary-cam, $brand-tertiary-cam);
    a {
      color: $gray-lightest;
    }
  }
  a {
    color: $gray-lightest;
    font-size: 1.6rem;
    text-decoration: none;
  }
}

.context-edit a {
  position: absolute;
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-primary;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  font-size: 0;
  &::before {
    font-family: $font-icon;
    content: '\f303';
    font-size: 1.25rem;
    color: $gray-lightest;
  }
  &:hover {
    background: $brand-primary-cam;
  }
}