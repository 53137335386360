.encart-date-lieu-web {
  display: inline-block;
  background-color: $brand-primary;
  padding: 20px;
  border: 1px solid $brand-primary-cam;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 1.6rem;
  line-height: 1.6;
  color: $gray-lightest;

  .encart-date .date-display-range::before,
  .encart-date .date-display-single::before {
    font-family: $font-icon;
    content: $fa-var-calendar-alt;
    font-size: 1.6rem;
    color: $gray-lightest;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .encart-lieu .locality::before {
    font-family: $font-icon;
    content: $fa-var-map-marker;
    font-size: 1.6rem;
    font-weight: 900;
    color: $gray-lightest;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .encart-lieu .location-hidden {
    display: none;
  }

  .encart-web a::before {
    font-family: $font-icon;
    content: $fa-var-external-link-alt;
    font-size: 1.6rem;
    font-weight: 900;
    color: $gray-lightest;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .encart-orga .even::before {
    font-family: $font-icon;
    content: $fa-var-users;
    font-weight: 900;
    font-size: 1.6rem;
    color: $gray-lightest;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
