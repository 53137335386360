.view-id-agenda {
  .form-item-field-event-value-1, .views-widget-filter-field_date_value, .views-submit-button {
    float: none;
    display: inline-block;
  }
  .views-exposed-widget .btn {
    margin-top: 1.4rem;
  }
  .view-grouping-header, .view-grouping-content h3 {
    font-weight: bold;
    padding: 1rem;
  }
  .view-grouping-header {
    margin: auto;
    font-size: 3rem;
    color: $brand-primary;
    /*width: 20rem;*/
    text-align: center;
  }
  .view-grouping-content h3 {
    background: $brand-secondary-cam;
    color: $text-color;
    margin-bottom: 3rem;
  }

  .views-field-field-event {
    float: right;
  }

}
