.eular-intro {
  .eular-img {
    padding-left: 0;
    padding-right: 0;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.eular-agenda,
.eular-newsletter {
  margin-top: 30px;
}

.eular-newsletter {
  clear: both;
  padding-left: 0;

  h2::before {
    font-family: $font-icon;
    content: $fa-var-newspaper;
    font-size: 1.6rem;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    @media screen and (min-width: 992px) {
      font-size: 2rem;
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.eular-agenda {
  h2::before {
    font-family: $font-icon;
    content: $fa-var-calendar-alt;
    font-size: 1.6rem;
    width: 20px;
    height: 20px;
    margin-right: 5px;

    @media screen and (min-width: 992px) {
      font-size: 2rem;
    }
  }
}

.bloc-retour-liste {
  margin-top: 10px;
}

.actualite-page-eular{
	margin-bottom: 25px;
    border-bottom: 1px solid #b7aca1;
    padding-bottom: 20px;
}

.actualite-page-eular{
	*:first-letter {
		text-transform:uppercase;
	}
	
	.view-footer{
		padding:20px 0;
	}
}
