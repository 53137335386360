.not-logged-in {
  &.page-user #user-login {
    display: none;
  }
  &.page-user-login #user-login {
    display: block;
  }
  
  &.page-user .page-header {
    padding-bottom: 0px;
    margin: 0;
    border-bottom: none;
  }
}

.connexion_creation_compte.inscriptions{
  @media screen and (min-width: 768px) {
    display:flex;
    justify-content:center;
  }
  
  .conversion_compte,
  .creation_compte,
  .connexion_compte,
  .adhesion_compte {
    margin:0 15px 15px 15px;
  }  
}

.connexion_creation_compte {
  margin: 3rem auto;
  max-width: 1170px;

  .conversion_compte,
  .creation_compte,
  .connexion_compte,
  .adhesion_compte {
    padding: 1rem 2rem 1.5rem 2rem;
    margin-bottom: 2rem;
    border-radius: 2rem;

    // @media screen and (min-width: 768px) {
    //   min-height: 300px;
    // }
    //
    // @media screen and (min-width: 992px) {
    //   min-height: 192px;
    // }

    h3,
    h4,
    p,
    a {
      color: $gray-lightest;
    }

    h3,
    h4 {
      font-weight: bold;
    }

    h3 {
      font-size: 2.2rem;
      margin-top: 10px;
      margin-bottom: 5px;

      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }

      @media screen and (min-width: 1200px) {
        font-size: 2.4rem;
      }
    }

    span {
      color: $brand-tertiary;
    }

    h4 {
      margin-top: 0px;
      margin-bottom: 15px;

      @media screen and (min-width: 768px) {
        font-size: 1.4rem;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.6rem;
      }
    }

    p {
      @media screen and (min-width: 768px) {
        font-size: 1.2rem;
      }

      @media screen and (min-width: 992px) {
        font-size: 1.4rem;
      }
    }

    a {
      text-align: center;
      padding: 10px;
      display: block;
      width: 75%;
      margin: auto;
      margin-bottom: 10px;
      border-radius: 5px;

      &:hover {
        text-decoration: none;
        background-color: $brand-secondary;
      }

      &:focus {
        text-decoration: none;
      }

      @media screen and (min-width: 1200px) {
        width: 55%;
        font-size: 1.6rem;
      }
    }
  }

  .conversion_compte {
    background: $brand-quaternary;

    @media screen and (min-width: 1200px) {
      margin-left: 0px;
    }

    a {
      background: $brand-quaternary-cam;
      position: relative;
      font-weight: bold;
    }
  }

  .connexion_compte {
    background: $brand-primary;

    .connexion_link {
      a {
        background: $brand-primary-cam;

        &:hover {
          background-color: $brand-secondary;
        }
      }

      a.password_forgotten {
        background: none;
        color: $brand-tertiary;
        width: 100%;
        padding: 0 10px;

        @media screen and (min-width: 1200px) {
          font-size: 1.4rem;
        }
      }
    }

  }

  .creation_compte {
    background: $brand-secondary;

    @media screen and (min-width: 768px) {
      max-width: 100%;
      min-height: auto;
      padding-bottom: 40px;
    }

    p span {
      color: $brand-tertiary-cam-dark;
    }

    a {
      background: $brand-secondary-cam;
      position: relative;
      bottom: -5px;

      &:hover {
        background-color: lighten($brand-secondary, 10%);
      }

      @media screen and (min-width: 768px) {
        bottom: -40px;
      }

      @media screen and (min-width: 992px) {
        bottom: -35px;
      }

      @media screen and (min-width: 1200px) {
        bottom: -27px;
      }
    }
  }

  .adhesion_compte {
    background: $brand-secondary;
    color: $gray-lightest;
    @media screen and (min-width: 768px) {
      padding-bottom: 2.5rem;
    }
    @media screen and (min-width: 1200px) {
      margin-left: 0px;
    }
    ol li::before {
      color: $gray-lightest;
    }
    a {
      position: relative;
      bottom: -7px;
      @media screen and (min-width: 768px) {
        bottom: -10px;
      }
      &:hover {
        opacity: .5;
        background: $brand-primary-cam;
        color: $gray-lightest;
      }
    }
    // @media screen and (min-width: 992px) {
    //   min-height: 480px;
    // }
  }
}

.page-node-195 {
  .connexion_creation_compte {
    margin: 0 0 10px;
    span {
      color: $brand-tertiary;
    }

    .creation_compte {
      @media screen and (min-width: 768px) {
        margin-left: 0;
      }
    }
  }

  aside {
    clear: both;

    @media screen and (min-width: 768px) {
      clear: none;
    }

    #block-block-7 {
      margin-top: 20%;

      @media screen and (min-width: 768px) {
        margin-top: 33%;
      }

      @media screen and (min-width:992px) {
        margin-top: 25%;
      }

      @media screen and (min-width:1200px) {
        margin-top: 20%;
      }
    }
  }
}

.user_page-avantages-adhesion {
  margin-bottom: 20px;

  h2 {
    padding-left: 10px;
    text-align: center;
  }

  .adhesion-avantages {
    margin-top: 20px;
    padding-bottom: 15px;

    .adhesions-avantages_items {
      p {
        margin-bottom: 10px;
        line-height: 1.8;
      }

      span {
        background: $brand-tertiary;
        padding: 4px 8px;
        margin-left: 5px;
        border-radius: 50%;
        font-weight: 600;
        color: $gray-lightest;
      }
    }
  }
}

.page-civicrm-contribute .crm-not-you-message {
  display: none;
}

// Mes informatons >> Mon compte

#socialfield-table > thead > tr > th {
  border-bottom: 0;
}

#social-field-wrapper {
  display: none;
  max-width: 800px;
}
#socialfield-table thead:nth-child(1) {
  display:none;
}
#socialfield-table tr td:nth-child(1) {
  width: 63px;
}
#socialfield-table tr td:nth-child(2) {
  > div {
    width: 100%;
  }
}

#edit-timezone {
  max-width: 800px;
  padding: 8px;
  .panel-heading {
    display: none;
  }
}
