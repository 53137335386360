// 05 Form Elements

.form-control {
  box-shadow: none;
  &[disabled], &[readonly] {
    background-color: $brand-secondary-cam;
    &::placeholder {
      color: $gray-light;
    }
  }
}

.label-text, .radio label, .checkbox label {
  font-weight: 300;
}

.small-text-field {
  width: 50%;
}

.has-success .form-control {
  border-color: #94C11F;
}
.has-error .form-control {
  border-color: $brand-danger;
}

// Input Select

.form-type-select:not([class*="webform-"]) {
  border: 1px solid $input-border;
  // overflow: hidden;
  position: relative;
  display: block;
  border-radius: $input-border-radius-small;
  &::after {
    font-family: $font-icon;
    content: $fa-var-caret-down;
    background: $gray-lightest;
    line-height: 2.5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    text-align: center;
    width: 3rem;
    height: 100%;
    pointer-events: none;
    box-sizing: border-box;
    font-weight: 900;
  }
  select {
    border: 0;
    appearance: none;
    &[required] > option:nth-child(1)::after {
      content : '*';
      position: relative;
    }
  }
}

// Radio / Checkbox buttons

.radio, .checkbox {
  width: auto;
  @media screen and (min-width: 768px) {
    display: inline-block;
  }
}

input[type="checkbox"], input[type="radio"], .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline {
  position: absolute;
  opacity: 0;
  margin-left: 0;
  & + .label-text::before, & + label::before {
    font-family: $font-icon;
    font-weight: normal;
    font-size: 2rem;
    // speak: none;
    line-height: 1;
    display: inline-block;
    margin-right: 1.5rem;
    color: $brand-primary;
  }
  & + .label-text + input[type="radio"], & + label + input[type="radio"] + label::before,
  & + .label-text + input[type="checkbox"], & + label + input[type="checkbox"] + label::before {
    margin-left: 1.5rem;
  }
  &:checked + .label-text::before, &:checked + label::before {
    color: $brand-primary;
    animation: effect 250ms ease-in;
  }
  &:disabled + .label-text, &:disabled + label {
    color: $gray-lighter;
  }
  &:disabled + .label-text:before, &:disabled + label::before {
    color: $gray-light;
  }
}

@keyframes effect {
  0% {
    transform: scale(0); }
  25% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

/*Check box*/

input[type="checkbox"] {
  & + .label-text::before, & + label::before {
    content: $fa-var-square;
  }
  &:checked + .label-text::before, &:checked + label::before {
    content: $fa-var-check-square;
  }
  &:disabled + .label-text::before, &:disabled + label::before {
    content: $fa-var-square;
  }
}

/*Radio box*/

input[type="radio"] {
  & + .label-text::before, & + label::before {
    content: $fa-var-circle;
  }
  &:checked + .label-text::before, &:checked + label::before {
    content: $fa-var-dot-circle;
  }
  &:disabled + .label-text::before, &:disabled + label::before {
    content: $fa-var-circle;
  }
}

// Alerts

.alert {
  strong {
    color: $gray-lightest
  }
  &::before, &::after {
    font-family: $font-icon;
    color: $brand-secondary-cam;
    font-weight: 900;
  }
  &::before {
    margin-right: .5rem;
  }
  &::after {
    float: right;
    margin-left: .5rem;
  }
}

// Input File

// .form-managed-file {
//   border: 0;
//   height: 4rem;
//   @include background-opacity($brand-secondary-cam, $opacity: .5)
//   input[type="file"] {
//     display: inline-block;
//     width: auto;
//   }
// }

.form-managed-file, .form-type-managed-file {
  input[type="file"] {
    display: inline-block;
    width: auto;
    // border: 0;
    height: 4rem;
    padding: 0;
  }
  .btn {
    display: inline-block;
    // top: -.2rem;
    // left: 1.5rem;
    position: relative;
  }
}

// Surcharge fieldset Panel Bootstrap

#webform-client-form-3, #webform-client-form-8, #webform-client-form-234, #edit-account, #edit-timezone {
  fieldset {
    border-color: none;
  }
  &.panel, .panel {
    border: none;
    box-shadow: none;
  }
  .panel-default {
    margin-bottom: 0;
    border: none;
    box-shadow: none;
    > .panel-heading {
      padding: 3rem 0 1.5rem 0;
      background-color: $gray-lightest;
      border: none;
      color: $brand-secondary;
    }
    .panel-title {
      display: block;
      font-size: 1.6rem;
      line-height: inherit;
      font-weight: bold;
      color: $brand-secondary;
      margin-bottom: 0;
    }
    .panel-body {
      padding: 0;
    }
  }
  legend {
    margin-bottom: 0;
  }
}