.footer {
  border: 0;
  margin-top: 3rem;
  padding-bottom: 0;
  padding-top: 0;
}
// // Marge Footer Epspace pero
//
//
// .page-user {
//   .footer {
//     margin-top: 0;
//   }
// }
//
// .page-user-edit {
//   .footer {
//     margin-top: 3rem;
//   }
// }

//

footer {
  background: $brand-primary;
  color: $gray-lightest;
  background-image: url("../img/fond.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 11%;
  padding-bottom: 0px;
  margin-top: 0;
  clear: both;

  .block-title {
    display: block;
    margin-bottom: 15px;
    font-size: 2rem;
    color: $brand-secondary-cam;
    text-align: left;
    font-weight: 300;
  }

  .site-footer {
    float: left;
    margin-right: 25px;

    p {
      text-align: center;
    }
  }

  .field-name-field-folder {
    display: none;
  }

  .media-element-container {
    float: left;
    margin-right: 10px;
  }
}
