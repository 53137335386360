#toTop {
  position: fixed;
  border-radius: 3px;
  background: $gray-light;
  opacity: .4;
  width: 55px;
  height: 55px;
  bottom: 75px;
  right: 5px;
  cursor: pointer;
  color: $gray-lightest;
  font-size: 32px;
  text-align: center;
  display: none;
  padding: 5px 0 0 2px;
  z-index: 14;
  @media only screen and (min-width: 480px) {
    bottom: 5px;
  }
  &:hover {
    opacity: 1;
    transition: all .3s;
  }
}
