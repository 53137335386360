.page-user-edit {
  .tabs-navigation {
    overflow: hidden;
    background: $brand-secondary;

    .tabs--primary {
      max-width: 1170px;
      border-bottom: none;
      margin: auto;

      li {
        display: inline-block;

        a {
          display: inline-block;
        }
      }
    }


    &:active {
      a {
        // font-weight: bold;
        border: none;
        border-radius: none;
        background: $brand-secondary-cam;
      }
    }

    .tabs--secondary {
      margin: auto;
      display: block;
      border-radius: 0;
      background: $brand-secondary-cam;
      text-align: left;
      width: 100%;
      padding-top: 0;
      border-top: none;

      @media screen and (min-width: 1200px) {
        max-width: 1170px;
      }

      &::before {
        background-color: $brand-secondary-cam;
        position: absolute;
        content: ' ';
        width: 100%;
        height: 41px;
        left: 0;
        right: 0;
      }

      li {
        // display: inline-block;
        float: left;

        a {
          border: none;
          border-radius: 0;
          background: $brand-secondary-cam;
          padding: 10px 12px;
          font-weight: normal;
          color: $brand-primary;

          @media screen and (min-width: 768px) {
            font-size: 1.4rem;
          }
        }
      }

      .active {
        display: inline-block;

        a {
          color: $brand-primary;
          font-weight: bold;
        }
      }

      li:first-child>a {
        min-width: 8rem;
      }
    }
  }

  .region-content {
    max-width: 1170px;
    margin: auto;
    margin-top: 25px;

    #edit-mimemail {
      display: none;
    }
  }

  .block-sfrh-user-public-profile,
  .block-cyim-user-profile-privacy-civicrm {
    h3 {
      margin-top: 5px;
    }
  }

  // Image profil

  .imagefield-crop-widget {
      display: flex;
      align-items: center;
  }

  .imagefield-crop-preview, .imagefield-crop-cropbox {
    display: inline-block;
  }

  .imagefield-crop-cropbox {
    margin-left: 3rem;
  }

  .field-widget-imagefield-crop-widget {
    .form-file {
      width:66%;
      max-width: 625px;
    }
    #edit-field-picture-und-0-remove-button {
      display: block;
      background: $gray-lightest;
      border: 0;
      padding: 0;
      &:hover {
        color: $text-color;
      }
    }
  }

  #user-profile-form {
    margin-bottom: 3rem;
    #edit-sso-body {
     margin-top: 35px;
   }
  }

  .field-type-social-links-field {
    .tabledrag-toggle-weight-wrapper {
      display: none;
    }

    #socialfield-table {
      .draggable a {
        display: none;
      }

      tr {
        background: $gray-lightest;

        td {
          border: none;
        }
      }
    }

  }
}
