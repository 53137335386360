.comite {
  h3 {
    clear: both;
    margin: 1.5rem 0;
  }
  .views-row {
    margin-bottom: 3rem;
    min-height: 123px;
  }
  // @media screen and (min-width: $screen-md-min) {
  //   .view-content > h3:not(:nth-child(1)) ~ .views-row:nth-child(3n+4) {
  //     clear: left;
  //   }
  // }

  .views-field-display-name, .views-field-nothing {
    background: $brand-primary;
    color: $gray-lightest;
    padding: 1rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .views-field-name-1,.views-field-city-1 {
    &::before {
      font-family: $font-icon;
      color: $brand-tertiary;
    }
  }
  .views-field-name-1 {
    &::before {
      content: '\f0f8';
    }
  }
  .views-field-city-1 {
    &::before {
      content: '\f3c5';
    }
  }
}
