.page-user-membership {
  .main-container .col-xs-12 {
    padding: 0;
  }

  .tabs-navigation {
    background: $brand-secondary;

    .tabs--primary {
      max-width: 1170px;
      border-bottom: none;
      margin: auto;

      li {
        display: inline-block;

        a {
          display: inline-block;
        }
      }
    }

    &:active {
      a {
        border: none;
        border-radius: none;
        background: $brand-secondary-cam;
      }
    }
  }

  #block-system-main {
    #block-sfrh-membership-user-profile-membership-status-block {
      background: $brand-secondary-cam;
      padding-bottom: 15px;

      .block-title {
        display: none;
      }

      .membership_adhesion {
        padding: 0px;
        max-width: 1170px;
        margin: auto;
        color: $gray-lightest;
        //line-height: 2.1rem;
        margin-top: 15px;

        .membership_card {
          background: $brand-primary;
          padding: 20px;
          margin: 15px 10px;

          @media screen and (min-width: 768px) {
            max-width: 30%;
            float: right;
          }

          .label-numero-de-membre,
          .numero {
            display: inline-block;
          }

          .numero {
            color: $brand-tertiary;
            font-weight: bold;
          }
        }

        .membership_card.notMember {
          border-radius: 0px;
          background: $brand-secondary;
          color: $gray-lightest;

          .membership_number .numero {
            color: $brand-tertiary-cam-dark;
          }
        }

        .membership_status.notMember::before {
          font-family: $font-icon;
          content: $fa-var-exclamation-triangle;
          color: $gray-lightest;
          font-size: 2rem;
          margin-right: 8px;
        }

        .membership_campaign {
          background: $brand-secondary-cam;
          padding: 10px;
          color: $text-color;

          @media screen and (min-width: 768px) {
            max-width: 60%;
            float: left;
          }

          .btn {
            margin-top: 1rem;
          }

          a:hover {
            text-decoration: none;
          }

          .mbs-avantages {
            margin-top: 10px;
            li::before {
              position: absolute;
              margin-left: -1.5rem;
              content: "⦁";
              color: $brand-tertiary;
              font-size: .9rem;
              line-height: 2.5;
              font-weight: 300;
            }
          }
        }
      }
    }

    #block-sfrh-membership-user-profile-cotisation-history-block {
      max-width: 1170px;
      margin: auto;

      .block-title {
        text-align: center;
      }

      .membership_info {
        .membership_fee_payement {
          display: none;

          @media screen and (min-width: 768px) {
            display: table-cell;
          }
        }

        .membership_fee_bill,
        .membership_fee_receipt {
          text-align: center;
        }
      }
    }
  }
}
