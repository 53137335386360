//style page recherche et innovations

.fond-intro-recherche {
  background-color: $brand-primary-light;
  padding: 2.5rem;
  h4 {
    color: $brand-primary;
  }
  ul {
   display: inline-flex;
   @media screen and (max-width: 1199px) {
    display: block;
    }
   li {
    padding-right: 4rem;
    @media screen and (max-width: 1199px) {
      padding-top:1rem;
      padding-right: unset;
      }
    &::before  {
      color: $brand-primary !important;
      font-size: 2.5rem !important;
      line-height: 2.8rem !important;
     }
     a {
      color: $brand-primary;
      font-weight: unset;
      text-decoration: underline;
     }
   }
  }
}
  

#node-120 {
  display: flex;
  align-items: center;
  justify-content: center;

//style h2

h2:not(.card-title)::before  {
  content: "⦁";
  color: $brand-tertiary !important;
  font-size: 3.5rem !important;
  position: absolute;
  left: -2rem;
 }

}

.card-body-1 h2,
.card-body-2 h2,
.card-body-3 h2,
.card-body-4 h2,
.card-body-5 h2,
.card-body-6 h2,
.card-body-7 h2,
.card-body-8 h2,
.card-body-9 h2,
.card-body-10 h2 {
  font-weight: normal;
}

.card-body-SFR {
  //background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/bg-engagement-sfr.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 65%;
  background-position-x: 55%;
  @media screen and (max-width: $screen-md-min) {
    background-size: 300%;
  }
  @media screen and (max-width: $screen-sm-min) {
    background-size: 800%;
  }
  h2 {
    color: $brand-primary-cam;
  }

  .bg-cadre-SFR {
    //background-color: rgba($brand-tertiary, 0.8);
    padding: 5rem;
    border-radius: 25px;
    @media screen and (min-width: $screen-sm-min) {
      &.long {
        padding: 3rem;
        h2 {
          margin: 0 auto;
        }
        p {
          margin: 1.5rem auto;
        }
        .btn {
          max-width: 14rem;
          margin: auto;
        }
      }
    }
  }
}

.card-body-1 {
  background-color: $brand-tertiary-cam;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-offre-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-1 {
    background-color: rgba($brand-tertiary, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.card-body-2 {
  background-color: $brand-secondary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-offre-2.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-2 {
    background-color: rgba($brand-secondary, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.card-body-3 {
  background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-offre-3.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-3 {
    background-color: rgba($brand-quaternary, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.card-body-4 {
  background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-bourse-1.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-4 {
    background-color: rgba($brand-tertiary, 0.8);
    padding: 5rem;
    border-radius: 25px;
    @media screen and (min-width: $screen-sm-min) {
      &.long {
        padding: 3rem;
        h2 {
          margin: 0 auto;
        }
        p {
          margin: 1.5rem auto;
        }
        .btn {
          max-width: 14rem;
          margin: auto;
        }
      }
    }
  }
}

.card-body-5 {
  background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-bourse-2.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-5 {
    background-color: rgba($brand-secondary, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.card-body-6 {
  background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-bourse-3.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-6 {
    background-color: rgba($brand-secondary, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.card-body-7 {
  background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-bourse-3.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
    &::before  {
      display: none;
     }
  }

  h3 {
    color: $gray-lightest;
    font-weight: normal;
  }

  .bg-cadre-7 {
    background-color: rgba($brand-quaternary, 0.8);
    padding: 5rem;
    border-radius: 25px;
    @media screen and (min-width: $screen-sm-min) {
      &.long {
        padding: 3rem;
        h2 {
          margin: 0 auto;
        }
        p {
          margin: 1.5rem auto;
        }
        .btn {
          max-width: 14rem;
          margin: auto;
        }
      }
    }
  }
}

.card-body-8 {
  background-color: $brand-quaternary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-offre-2.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-8 {
    background-color: rgba($brand-quaternary, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.card-body-9 {
  background-color: $brand-secondary;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-bourse-1.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-9 {
    background-color: rgba($brand-secondary, 0.8);
    padding: 5rem 1rem;
    border-radius: 25px;
  }
}

.card-body-10 {
  background-color: $brand-tertiary-cam;
  border-radius: 25px;
  margin-top: 2rem;
  background-image: url("../img/img-btn-bourse-2.jpg");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-y: 65%;
  background-position-x: 55%;

  h2 {
    color: $gray-lightest;
  }

  .bg-cadre-10 {
    background-color: rgba($brand-tertiary-cam, 0.8);
    padding: 5rem;
    border-radius: 25px;
  }
}

.node-bourse-resultats .paragraphs-item-texte img {
  height: 2.5rem;
}
