// 02 Typography

//== Font Family
$font-family-sans-serif: 'Muli', sans-serif;

//== Bootstrap Glyphicons path
$icon-font-path: "../../../../../themes/theme-d8-tb_bootstrap_cyim/vendor/bootstrap-sass/assets/fonts/bootstrap/";

// Font Muli

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli Regular'), local('Muli-Regular'), url(https://fonts.gstatic.com/s/muli/v11/7Auwp_0qiz-afTLGLQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: local('Muli Light'), local('Muli-Light'), url(https://fonts.gstatic.com/s/muli/v11/7Au_p_0qiz-adZnkOCX2zw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: local('Muli SemiBold'), local('Muli-SemiBold'), url(https://fonts.gstatic.com/s/muli/v11/7Au_p_0qiz-adZXgOCX2zw.woff2) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 800;
  src: local('Muli ExtraBold'), local('Muli-ExtraBold'), url(https://fonts.gstatic.com/s/muli/v11/7Au_p_0qiz-adZXgOCX2zw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  @media screen and (max-width: 767px) {
    font-size: 8.5px;
  }
}

body {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-size: 1.6rem;
}

// Titles & Headings

.page-header {
  margin: 4rem 0;
  border-bottom: 1px solid $brand-secondary-cam;
}

h1 {
  color: $brand-primary;
  font-weight: light;
  font-size: 3.6rem;
}
h2 {
  color: $brand-secondary;
  font-size: 2.4rem;
  font-weight: bold;
}
h3 {
  color: $brand-primary;
  font-size: 1.8rem;
  font-weight: bold;
}
h4 {
  color: $brand-secondary;
  font-size: 1.8rem;
  font-weight: bold;
}
h5 {
  color: $brand-primary;
  font-size: 1.8rem;
}
h6 {
  color: $brand-secondary;
  font-size: 1.8rem;
}

// Link & Lists

.region-content, .navbar, .region-home-agenda-headline {
  a {
    font-weight: bold;
  }
  ul:not(.view-id-annonce li) {
    li {
      list-style: none;
      &::before {
        position: absolute;
        margin-left: -1.5rem;
        content: "⦁";
        color: $brand-tertiary;
        font-size: .9rem;
        line-height: 2.5;
        font-weight: 300;
      }
    }
  }
  td.participant_fee_level{
    ul {
      li {
        &::before {
          display: none;
        }
      }
    }
  }

  ol {
    li {
      list-style: none;
      counter-increment: li;
      &::before {
        margin-left: -1.5rem;
        content: counter(li)'. ';
        color: $brand-tertiary;
        font-weight: 300;
      }
    }
  }

  p:not(.text-center) {
    @media screen and (min-width: 768px) {
      text-align: justify;
    }
  }
}

// Body Text

p {
  line-height: 1.25;
  margin-bottom: .8rem;
}

strong {
  color: $brand-primary-cam;
}

blockquote {
  padding: 0 2rem;
  margin: 2rem 0;
  font-size: 1.6rem;
  font-family: serif;
  border-color: $brand-tertiary-cam;
  color: $brand-primary;
  line-height: 1.286;
}
