
.page-user {
  .background-breadcrumb {
    margin-bottom: 0;
    a {
      background: none!important;
    }
  }
  .main-container.container-fluid {
    padding-right: 0;
    margin-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin: auto;

    h1 {
      color: $gray-lightest;
    }
  }
  .col-xs-12 {
    padding: 0;
  }
  .page-header {
    padding-left: 15px;
  }
  .tabs-navigation {
    .nav-tabs {
      max-width: 1170px;
      border-bottom: none;
      margin: auto;

      li {
        display: inline-block;

        a {
          display: inline-block;
          border-radius: 0;
          background: transparent;
          border: none;
          color: $brand-primary;
          padding: 10px;
          font-size: 1.2rem;
          float: none;

          @media screen and (min-width: 768px) {
            padding: 15px;
            font-size: 1.6rem;
          }
        }
      }
      li.active {
        background: #F0F0F0;
        cursor: pointer;

        a {
          font-weight: bold;
          border: none;
          color: $brand-primary;
        }
      }
    }
  }

  
  // Cas user anonyme
  .anonymous_user-page {
    margin-top: 2rem;
  }
  #block-sfrh-user-public-profile-user-public-profile-block .public_profil_summary {
    .user-card {
      background: $brand-secondary-cam;
      padding-left: 0;
      padding-right: 0;

      .user-card-item {
        padding-top: 3rem;
        padding-bottom: 3rem;

        .user-picture {
          padding-left: 0;
        }

        .user-identity {
          h2 {
            margin-top: 0;
            margin-bottom: .5rem;

            em {
              font-style: normal;
              color: $brand-primary;
              font-size: 1.8rem;
              font-weight: 600;
            }
          }

          .specialite span {
            font-weight: 600;
          }

          .identity_block {
            .contact-me {
              font-weight: bold;
              margin-bottom: 0;
            }

            .contact-item {
              margin-bottom: 0;
            }

            em {
              font-style: normal;
            }
          }
          .bouton-edit-profil .btn {
            margin-top: 1rem;
          }
        }
        .user-social_links ul {
          overflow: hidden;
          li {
            .icon {
              border-radius: 8px;
              background-color: $brand-secondary;
            }

            &:before {
              content: '';
            }
            &:first-of-type {
              margin-left: 0 !important;
            }
          }
        }
        .membership_card {
          background: $brand-primary;
          padding: 20px;
          margin: 15px 10px;
          color: $gray-lightest;
          border-radius: $btn-border-radius-large;
          .number {
            color: $brand-tertiary;
          }
          .alert {
            padding: 0;
          }
          .bouton-adhesion {
            margin-top: 1.5rem;
          }
        }
      }
    }
    .coordonnees_block {
      padding: 3rem 1.5rem;
      .text-user,
      .contact-me {
        color: $brand-secondary;
      }
    }

    .coordonnees_block h3,
    .associatif_block h3 {
      text-align: center;
      font-weight: 600;

      @media screen and (min-width: 1200px) {
        font-size: 2.4rem;
      }
    }

    .coordonnees_block .coordonnees-item {
      position: relative;
      padding: 1rem 1.5rem;
      border: 1px solid $brand-secondary-cam;
      margin-left: 0;
      margin-right: 0;

      @media screen and (min-width: 1200px) {
        max-width: 1170px;
        margin: auto;
      }
      .coordonnees_pro,
      .coordonnees_perso {
        padding-left: 0;
        padding-right: 0;
      }
      h4 {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: .5rem;

        @media screen and (min-width: 1200px) {
          font-size: 1.8rem;
        }
      }
      p {
        font-size: 1.2rem;

        @media screen and (min-width: 1200px) {
          font-size: 1.6rem;
        }
      }
    }
    .coordonnees_block .coordonnees-item p,
    .associatif_block .associatif-item p {
      margin-bottom: 0;
    }
    .associatif_block {
      background: $brand-primary;

      h3 {
        background-color: $gray-lightest;
        margin-bottom: 0;
        padding-bottom: 10px;
      }

      .table-responsive {
        border: none;
        table {
          thead th {
            color: $brand-secondary;
            font-size: 1.2rem;
            border: none;
            border-bottom: 1px solid $brand-secondary;
            padding-left: 0;
            background: $brand-primary;

            @media screen and (min-width: 768px) {
              padding-top: 3rem;
            }
            @media screen and (min-width : 1200px) {
              font-size: 1.6rem;
            }
          }
          tbody td {
            color: $gray-lightest;
            font-size: 1.1rem;
            border: none;
            padding-left: 0;
            padding-bottom: 2px;
            background: $brand-primary;

            @media screen and (min-width: 768px) {
              font-size: 1.2rem;
            }

            @media screen and (min-width : 1200px) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
    .associatif-item {
      color: $gray-lightest;
      padding-bottom: 10px;
      overflow: auto;
      padding-left: 15px;

      @media screen and (min-width: 1200px) {
        padding-left: 0;
      }
      .info-member {
        @media screen and (min-width: 768px) {
          float: right;
        }
        .member_number {
          width: 35%;
          margin: auto;
          float: right;
          margin-right: 10px;

          @media screen and (min-width: 768px) {
            float: none;
            width: 80%;
            margin-right: 0;
          }
          p {
            background: $brand-tertiary;
            padding: 5px;
            text-align: center;
            margin-bottom: 0px;

            &.text-user {
              padding-bottom: 0px;
              text-transform: uppercase;
            }

            &.membership-number {
              font-size: 2rem;
              font-weight: 600;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              margin-bottom: 5px;
              margin-top: 0;
              padding-top: 0;
              line-height: 1.2;
            }
          }
        }
        .member_info {
          text-align: left;
          padding-top: 5px;

          @media screen and (min-width: 768px) {
            text-align: center;
          }

          .statut,
          .join_date {
            color: $gray-lightest;
          }
          .statut {
            text-transform: uppercase;
            font-size: 1.6rem;
            margin-bottom: 0;

            @media screen and (min-width: 1200px) {
              font-size: 2rem;
            }
          }
          .join_date {
            font-size: 1.2rem;
            padding-bottom: 10px;

            @media screen and (min-width: 1200px) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
  // Message Inscription
  .alert-inscription, .no-event-area {
    padding: 3rem 0;
    background: $brand-secondary-cam;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 0;
      border: 2px solid $brand-primary;
      border-radius: 5px;
    }
  }

  // Onglet Inscriptions
  #block-sfrh-inscription-user-profile-inscription-status-block {
    background: #F0F0F0;
    padding-bottom: 20px;

    .inscription_title{
      font-size: 34px;
      color: #522A5B!important;
      font-weight: 500;
      border-bottom: 1px solid #d3d3d3;
      padding-bottom: 20px;

      @media screen and (min-width: 320px) and (max-width: 460px) {
        text-align: center;
      }
    }
    .row{
      border-bottom: 1px solid #949494;
      padding-bottom: 2%;
      padding-top: 2%;
    }
    // Cas inscription exposant
    .row-exposant{
      border: none;
      .inscription_title{
        border: none;
      }
    }
    .block-title {
      display: none;
    }
    h3 {
      font-weight: 600;
      padding-left: 15px;
      line-height: 1.5;
    }
    .inscription-subcription {
      max-width: 1170;
      
      .current-event-area{
        @media screen and (max-width: 992px) {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
        }

        @media screen and (min-width: 320px) and (max-width: 690px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%!important;
        }
        @media screen and (max-width: 820px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

        }
        .col-sm-3 {
          @media screen and (min-width: 320px) and (max-width: 690px) {
            width: 65%!important;
          }
          @media screen and (max-width: 820px) {
            width: 80%!important;
            height: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }

        }
      }
    }
    .event-info-card {
      // margin-top: 58px;
      border-radius: 3px;
      line-height: 2;
      cursor: pointer;
      padding: 0;

      .event-affiche{
        width: 100%;
        height: 100%;
        @media screen and (min-width: 320px) and (max-width: 690px){
          width: 100%;
        }
        
      }

      span{
        display: none;
      }
      .event-name{
        display: flex;
      }

      @media screen and (max-width: 992px) {
        background-size: 100% 100%;
        height: 300px;
      }
      @media screen and (min-width: 320px) and (max-width: 690px) {
        background-size: 100% 100%!important;
        height: 350px;
      }
      @media screen and (max-width: 820px) {
        background-size: 100% 100%!important;
        height: 350px;
      }

      .plateforme-btn{
        background-color: #F39500;
        color: white;
        top: 60%;
        position: absolute;

        @media screen and (max-width: 992px) {
          border: 1px solid red;
        }
      }
      
    }
    .event-name{
      background-color: $brand-event;
      width: 100%;
      padding: 5px;
      border-radius: 20px 20px 0px 0px;
      color: white;
      text-align: center;
      font-size: 19px;
      font-weight: 500;

      @media screen and (max-width: 1199px) {
        width: 80%;
        font-size: 14px;
        top: -9%;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
      @media screen and (min-width: 320px) and (max-width: 690px) {
        width: 100%;        
      }
    }
    // Cas inscription exposant
    .event-name-exposant{
      background-color: $brand-tertiary-cam;
    }
    .event-dates{
      display: none;
    }
    .event-register-dates-limit{
      display: none;
    }
    .btn-primary{
      background-color:#7AA222!important;
      border: none;
      margin-bottom: 8%;
    }
    .btn-primary{
      background-color:#7AA222!important;
      border: none;
    }
    .inscription-card {
      margin-top: 2%;
      display: grid;
      grid-template-columns: 2fr 2fr;
        @media screen and (max-width: 992px) {
          // margin-left: 0!important;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
        @media screen and (min-width: 320px) and (max-width: 690px) {
          justify-content: center;
          align-items: center;
        }
        @media screen and (max-width: 820px) {
          justify-content: center;
          align-items: center;
        }
      }
      h3{
        font-size: 24px;
        text-align: center;
        font-weight:500;
        margin: 0;
      }
    }
    .inscription-item{
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      

      @media screen and (max-width: 992px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
      }
      @media screen and (min-width: 320px) and (max-width: 690px) {
        justify-content: center;
        align-items: center; 
      }
      @media screen and (max-width: 820px) {
        justify-content: center;
        align-items: center; 
      }
    }
    .event-description {
      margin-top: 1.5rem;
      img {
        margin-left: 0;
        margin-right: 1.5rem;;
      }
    }
    .individual-subcription, .group-subcription, .presse-subcription {
      width: 100%;
      .wrapper {
        min-height: 25rem;;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: $gray-lightest;
        padding: 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 3px;
        margin-bottom: 3rem;
        .group-important {
          margin-top: 20px;
          font-size: 12px;
        }
        .pliage-badge-img {
          margin: 0 auto;
        }
        .attestation {
          margin-left: 20px;
        }
        .printBadge {
          margin: 15px 0px;
        }
      }
      .ins-title{
        width: 109.5%;
        height: 70px;
        background-color:#F5F3F2;
        margin-top: -5%;
      }
      .title {
        font-size: 2.5rem;
        font-weight: 600;
        padding-left: 1rem;
        color: $brand-primary;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.1;
        max-width: 100%;
        margin-bottom: 20px;
        height: 100%;

        &::before {
          font-family: $font-icon;
          font-size: 1em;
          margin-right: 1.5rem;
        }
        @media screen and (max-width: 1199px) {
          font-size: 2.3rem;
        }
        @media screen and (min-width: 320px) and (max-width: 690px) {
          font-size: 2rem;
        }
      }
      .body {
        width: 100%;
        text-align: center;
      }
      .info-registration-by{
        @media screen and (max-width: 1199px) {
          font-size: 15px;
        }
        @media screen and (min-width: 320px) and (max-width: 690px) {
          font-size: 12px;
        }
      }
    }
    .individual-subcription {
      .wrapper {
        min-height: 18rem;
      }
      .title::before {
        content: $fa-var-user;
      }
     
    }
    .presse-subcription {
      .btn-secondary{
        margin-bottom: 10%;
      }
      .wrapper {
        min-height: 18rem;;
      }
      .title::before {
        content: $fa-var-id-card-alt;
      }
    }
    .group-subcription-exposant{
      grid-column: 1;
    }
    .group-subcription-congres{
      grid-column: 2;
    }
    .group-subcription {
      .title::before {
        content: $fa-var-users;
      }
      .group-item-wrapper {
        margin-top: 1.5rem;
        padding: .8rem 1rem;
        background: $brand-secondary-cam;
        color: $brand-primary;
        width: 100%;
        &:hover {
          background: $brand-secondary;
        }
      }
      .group-item {
        display: flex;
        justify-content: flex-start;
        align-content: content;

        i{
          border-radius: 50%;
          width: 35px;
          height: 35px;
          background-color: white;
          border: 1px solid #522A5B;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .subtitle{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -8%;

        @media screen and (max-width: 1199px) {
          margin-top: -5%;
        }
        @media screen and (min-width: 320px) and (max-width: 690px) {
          margin-top: -10%;
          font-size: 12px;
        }
      }
      .group-text {
        width: 90%;
        
      }
      .fa {
        width: 10%;
        text-align: center;
        margin-top: .8rem;
      }
      .group-name {
        font-weight: 600;
      }
      .group-status {
        font-size: 1.2rem;
        font-weight: 400;
        display: block;
        color: #7AA222;
        &::before {
          font-family: $font-icon;
          content: $fa-var-circle;
          margin-right:3%;
        }
      }
      .button-inscription-other {
        margin-top: 1.5rem;
        color: #522A5B!important;
        font-weight: 400;
        text-decoration: underline;
      }
    }
    // Cas inscription exposant
    .button-exposant{
      margin-top: 5%;
    }
    .btn-default.close {
      opacity: 1;
      background: $brand-secondary-cam;
      border-color: $brand-secondary-cam;
      &:hover, &:focus, &:active {
        background: $brand-secondary-cam;
        border-color: $brand-secondary-cam;
        box-shadow: 0;
        cursor: default;
        color: $gray-lightest;
      }
    }
    .btn-tertiary.close {
      opacity: 1;
      &:hover, &:focus, &:active {
        background: $brand-tertiary;
        border-color: $brand-tertiary;
        box-shadow: 0;
        cursor: default;
      }
    }
    .break {
      display: block;
    }
  }

  // Pas d'évènement
  .no-event-area {
    padding: 3rem;
    border: 2px solid $brand-primary;
    border-radius: 5px;
    display: block;
    margin: 3rem 0 1rem 0;
  }


.inscription-img-box{
  width: 100%;
  height: 10em;
  @media screen and (max-width: 992px) {
    height: 7em;
  }
  @media screen and (max-width: 768px) {
    height: 23em;
  }
  @media screen and (max-width: 375px) {
    height: 17em;
  }

  .inscription-img{
    background-image: url(../img/banner-app-mobile-page-inscription.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    
    @media screen and (max-width: 768px) {
      background-image: url(../img/banner-app-mobile-page-inscription-m.png);
    }
  }
}