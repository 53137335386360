// Page app mobile
#block-block-26 {
  .node--id--43.view-mode--full .field--name-field-content > .field__item {
    position: relative;
  }

  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item
    .bloc-app-mobile  {
    position: relative;
  }

  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item
    .bloc-app-mobile ::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
    background-color: transparent;
    background-image: url("../img/bg_replay_sfr.jpg");
  }

  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1) {
    height: 40rem;
  }

  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1) {
      height: 34rem;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
    background-color: transparent;
    background-image: url("../img/bg_replay_sfr.jpg");
    background-position-x: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)::before {
      background-size: contain;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-left {
    position: absolute;
    top: 2rem;
    left: 1rem;
  }

  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-left {
      position: relative;
      top: 11rem;
      left: unset;
    }
  }
  @media screen and (min-width: 768px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-left {
      top: 10rem;
      right: 3rem;
    }
  }
  @media screen and (min-width: 1400px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-left {
      left: 12rem;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-left
    .app-mob-text-intro {
    font-size: rem;
    font-weight: bold;
    color: #482551;
    font-style: normal;
  }

  @media screen and (min-width: 768px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-left
      .app-mob-text-intro {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-left
      .app-mob-text-intro {
      text-align: center;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-left
    .app-mob-text-intro
    .hilight {
    background-color: #f6a331;
    padding: 0.2rem 0.5rem;
    color: white;
  }

  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-right {
    position: absolute;
    top: 2rem;
    right: 1rem;
  }

  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right {
      position: relative;
      top: 12rem;
      left: unset;
      text-align: center;
      right: 0;
    }
  }
  @media screen and (min-width: 768px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right {
      top: 10rem;
      right: 3rem;
    }
  }
  @media screen and (min-width: 1400px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right {
      right: 10rem;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-right
    .text-app {
    color: #482551;
    font-weight: bold;
    font-size: 1.2rem;
    font-style: normal;
  }

  @media screen and (min-width: 992px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right
      .text-app {
      font-size: 1.6rem;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-right
    .qr-code {
    max-width: 10rem;
  }

  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right
      .qr-code {
      display: inline-grid;
    }
  }
  @media screen and (min-width: 768px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right
      .qr-code {
      max-width: 8rem;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-right
    .field__item:nth-of-type(2),
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(1)
    .col-right
    .field__item:nth-of-type(3) {
    margin-top: 1rem;
  }

  @media screen and (max-width: 576px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right
      .field__item:nth-of-type(2),
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(1)
      .col-right
      .field__item:nth-of-type(3) {
      display: inline-grid;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(2) {
    position: relative;
  }

  @media screen and (min-width: 992px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(2)::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: 100%;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      top: 0;
      background-color: transparent;
      background-color: #482551;
      height: 50rem;
      top: -30rem;
      z-index: -4;
    }
  }
  @media screen and (min-width: 991px) {
    .node--id--43.view-mode--full
      .field--name-field-content
      > .field__item:nth-of-type(2)
      .alert {
      width: 70%;
      margin: auto;
    }
  }
  .node--id--43.view-mode--full
    .field--name-field-content
    > .field__item:nth-of-type(3)::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100%;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
    background-color: transparent;
    background-image: url("../../img/bg_abstract_sfr.jpg");
  }

  .bloc-app-arguments {
    background-color: #f5f5f5;
  }

  @media screen and (max-width: 991px) {
    .row-text-img-app {
      display: block;
    }
  }
  @media screen and (min-width: 1200px) {
    .col-12.bloc-arguments {
      display: inline-flex;
    }
  }
  @media screen and (max-width: 1199px) {
    .col-arguments {
      margin: auto;
    }
  }
  @media screen and (max-width: 991px) {
    .col-text-img-app {
      text-align: center;
    }
  }
  .row-text-img-app {
    margin-top: 2.2rem;
  }
  @media screen and (max-width: 575px) {
    .img_mockup {
      text-align: center;
      margin-top: 25rem;
    }
  }

  .callout-warning {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    border: 1px solid #f8f9fa;
    border-left-width: 0.25rem;
    border-left-color: #f6a331;
    color: #f6a331;
  }

  .bloc-app-callout {
    background-color: #fffaef;
    padding: 0 !important;
    border: 1px solid #f6a331;
    margin-top: 4rem !important;
  }

  .bloc-app-callout > .callout-warning > h3 {
    color: #f6a331;
  }

  .app-callout-section {
    position: relative;
    margin: 1.5rem;
  }

  @media screen and (min-width: 992px) {
    .bloc-app-callout-section::before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100vw;
      height: 15rem !important;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      top: 0;
      background-color: transparent;
      background-color: #482551;
      height: 50rem;
      top: -30rem;
      z-index: -4;
      top: -6rem;
    }
  }
}
