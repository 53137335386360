#header-top {
  position: fixed;
  z-index: 995;
  width: 100%;
  padding: 0.4rem;
  max-height: 53px;
  border-bottom: 1px solid $gray-lighter;
  background: $gray-lightest;
  .bandeau-sites {
    text-align:center;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-lightest;
      height: 3.9rem;
      margin: 0 .25rem;
      padding: .25rem .8rem;
      width: 11.2rem;
      font-size: 1.15rem;
      line-height: 1.25;
      border-radius: $btn-border-radius-small;
      &:nth-child(1) {
        background: #f39500;
      }
      &:nth-child(2) {
        background: #db007a;
      }
      &:nth-child(3) {
        background: #7aa222;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
}
