.page-user-inscription {
  .main-container .col-xs-12 {
    padding: 0;
  }

  .tabs-navigation {
    background: $brand-secondary;

    .tabs--primary {
      max-width: 1170px;
      border-bottom: none;
      margin: auto;

      li {
        display: inline-block;

        a {
          display: inline-block;
        }
      }
    }

    &:active {
      a {
        border: none;
        border-radius: none;
        background: $brand-secondary-cam;
      }
    }
  }

  #block-system-main {
    #block-sfrh-inscription-user-profile-participations-history-block {
      max-width: 1170px;
      margin: auto;
      position: inherit;

      .block-title {
        text-align: center;
      }

      .inscriptions_info {
        .event_date,
        .participant_fee_level,
        .participant_register_date {
          display: none;

          @media screen and (min-width: 768px) {
            display: table-cell;
          }
        }

        .center_col {
          text-align: center;
        }

        td ul {
          padding: 0px;
        }
      }
    }
  }


}

#block-block-25 {
  background-color: #F0F0F0;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
}
.qrcode-container{
  h4{
    color:$brand-primary-cam;
    text-align: center;
  }
  .qrcode-box{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p{
    width: 50%;
    text-align: left!important;
  }
}