.view-actualites {
  .view-content {
    @media (min-width: $screen-sm-min) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .actu {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 1rem;
    }
    @media (min-width: $screen-sm-min) {
      padding-bottom: 4rem;
    }
    p {
      text-align: left;
    }
    .thumbnail {
      border: 0;
      h2,
      h3 {
        font-weight: bold;
        margin-bottom: .5rem;
      }
      .date {
        margin-bottom: 1rem;
        display: block;
      }
      .btn {
        @media (min-width: $screen-sm-min) {
          position: absolute;
          bottom: 2rem;
        }
      }
    }
  }
}

// Gestion TAG actualités
.categories_actualites {
 font-size:13px;
 width: 9rem;
 padding: 0.3rem 0;
 text-align: center;
 color: $gray-lightest;
}
// TAG Info SFR
.categories_actualites.class20 {
  background-color: $brand-primary-cam;
}
// TAG General
.categories_actualites.class21 {
  background-color: $brand-general;
}
// TAG Congrès
.categories_actualites.class22 {
  background-color: $brand-event;
}
// TAG Evenement
.categories_actualites.class25 {
  background-color: $brand-event;
}
// TAG COVID-19
.categories_actualites.class51 {
  background-color: $brand-primary-cam;
}
// TAG Recherche
.categories_actualites.class66 {
  background-color: $brand-recherche;
}
// TAG bourse
.categories_actualites.class24 {
  background-color: $brand-recherche;
}
// TAG Recommandation-produit
.categories_actualites.class65 {
  background-color: $brand-quaternary-cam;
  width: 18rem;
}
// TAG formation
.categories_actualites.class23 {
  background-color: $brand-recherche;
}
.page-actualites h1 {
  color: $gray-lightest;
}
.page-actualites,
.node-type-actualites {
  

  //Gestion de tous les liens de la page
  a:hover {
    text-decoration: none;
  }

  .main-container .container {
    padding-left: 0;
    padding-right: 0;
  }

  #top-content {
    background: $brand-secondary-cam;
    padding-bottom: 2rem;
    padding-top: 1rem;

    .top_actus {
      padding: 2rem 0 2.5rem 0;
      ul {
        list-style: none;
        padding-left: 0;
        margin-top: 1rem;

        .top-actus-item {
          .top-actu-title-tags {
            padding: .5rem 1.5rem;
          }

          .top-actu-tags {
            a {
              font-size: 1.4rem;
              color: $brand-secondary;
            }

            &::before {
              font-family: $font-icon;
              content: $fa-var-tag;
              font-weight: 900;
              font-size: 1.4rem;
              color: $brand-secondary;
              width: 20px;
              height: 20px;
              margin-right: .5rem;
            }
          }

          .card-title {
            margin-bottom: .5rem;
            margin-top: .5rem;
            font-weight: 600;
            font-size: 2rem;

            a {
              color: $brand-primary;
            }
          }

          .date {
            font-size: 1.4rem;
            color: $brand-secondary;

            &::before {
              font-family: $font-icon;
              content: $fa-var-calendar-alt;
              font-size: 1.6rem;
              color: $brand-secondary;
              width: 20px;
              height: 20px;
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }

  //Gestion de la vue actualites
  .view-id-actualites {
    padding: 0;

    .actus-list {
      ul {
        list-style: none;
        padding-left: 0;
        margin-top: 1rem;
        li {
          padding: 3rem 0;
          border-bottom: 1px solid $brand-secondary-cam;
          overflow: auto;

          &::before {
            content: ' ';
          }
        }
        li.views-row-last {
          border-bottom: none;
        }
      }
    }
    .actus-item {
      h3 {
        margin-bottom: .5rem;
      }

      // .actus-caption {
      //   padding-left: 0;
      //   padding-right: 0;
      // }

      .card-title {
        margin-bottom: .5rem;
        margin-top: .5rem;

        a {
          color: $brand-primary;
          font-weight: 500;
        }
      }

      // .actus-text {
      //   padding-left: 0;
      //   padding-right: 0;
      // }

      // .actus-text.col-xs-8 {
      //   padding-left: 1rem;
      // }

      .actus-tags {
        a {
          font-size: 1.4rem;
          font-weight: normal;
          color: $brand-secondary;
        }

        &::before {
          font-family: $font-icon;
          content: $fa-var-tag;
          font-weight: 900;
          font-size: 1.4rem;
          color: $brand-secondary;
          width: 20px;
          height: 20px;
          margin-right: .5rem;
        }
      }

      .actus-date span {
        font-size: 1.4rem;
        color: $brand-secondary;

        &::before {
          font-family: $font-icon;
          content: $fa-var-calendar-alt;
          font-size: 1.6rem;
          color: $brand-secondary;
          width: 20px;
          height: 20px;
          margin-right: .5rem;
        }
      }

      .btn-primary {
        margin-top: 1rem;
      }
    }
  }

  // Gestion tags
  aside.sidebar {
    @media screen and (max-width: 767px) {
      clear: both;
    }
  }

  .bef-select-as-checkboxes input[type="checkbox"] + label::before {
    content: '';
    margin-right: 0;
  }

  #block-views-exp-actualites-page {
    margin-top: 4rem;
  }

  #block-views-exp-actualites-page,
  #block-views-actualites-block-1 {
    h2 {
      color: $brand-primary;
      text-transform: uppercase;
      border-bottom: 1px solid $brand-secondary;
      padding-bottom: 1rem;
      font-weight: 500;
      font-size: 1.6rem;
      &::before {
        font-family: "Font Awesome 5 Free", fantasy;
        content: $fa-var-tags;
        color: rgb(183, 172, 161);
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }
    }
  }

  #block-views-exp-actualites-page #views-exposed-form-actualites-page {
    .views-widget-filter-field_taxonomie_actualites_tid {
      .form-item-field-taxonomie-actualites-tid {
        border: 0;
        &::after {
          content: '';
        }
      }
      > label {
        display: none;
      }
    }

    .bef-select-as-checkboxes {
      border: none;
      height: auto;
    }

    .form-type-bef-checkbox {
      display: inline-block;
      border: 1px solid $brand-secondary-cam;
      color: $brand-primary;
      // padding: 5px 10px;
      margin-right: 5px;
      margin-bottom: 5px;
      border-radius: 5px;

      &.highlight {
        background: $brand-primary;
        color: $gray-lightest;
      }

      &:hover {
        background: $brand-primary;
        color: $gray-lightest;
        cursor: pointer;
      }

      label {
        padding: .5rem 1rem;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }

        @media screen and (min-width: 768px) {
          font-size: 1.2rem;
        }
      }
    }

    input[type="checkbox"] {
      display: none;
    }
  }

  #block-views-actualites-block-1 {
    h2::before {
      font-family: $font-icon;
      content: $fa-var-newspaper;
      color: $brand-secondary;
      width: 20px;
      height: 20px;
      margin-right: .5rem;
    }

    .view-id-actualites {
      ul {
        padding-left: 0;
        margin-top: 1.5rem;

        li {
          list-style: none;
          margin-bottom: 2.5rem;

          .actus-caption {
            padding-left: 0;
            padding-right: 0;
            margin-right: 1rem;
          }

          .card-title {
            margin-bottom: .5rem;
            margin-top: .5rem;

            a {
              font-weight: bold;
              color: $brand-primary;
              font-size: 1.4rem;
            }
          }

          .actus-date .date {
            font-size: 1.4rem;
            color: $brand-secondary;

            &::before {
              font-family: $font-icon;
              content: $fa-var-calendar-alt;
              font-size: 1.6rem;
              color: $brand-secondary;
              width: 20px;
              height: 20px;
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }
}

.page-actualites {
  .views-submit-button {
    text-align: center;
    float: none;
    .btn {
      text-align: center;
      margin: 0 auto;
    }
  }
  .more-link {
    text-align: center;
    margin: 3rem auto;
  }
}

// Sidebar Dernières actualités

.dernieres_actus_list {
  .views-row {
    clear: both;
    margin-bottom: 3rem;
  }
}

//cache la date de mise à jour du contenu covid19
#node-472 {
  header {
    .submitted {
      display:none;
    }
  }
}

/**
 * Node 718 / Congres Partner Cost Page.
 */

 #node-718 h6{
  color: #94c11f;
  font-weight: bold;
 }

 #node-718 h2, #node-718 h4{
 color: #94c11f;
}

#node-718 .panel-heading{
  background-color: #6e417d;
  color: white;
 }

 #node-718 h3, #node-718 h6{
  color: #94c11f;
 font-size: 2rem;

 }

 #node-718 .price{
  text-align: right;

 }

 .webform-client-form-721 .checkbox {
  display: block;
 }

.webform-client-form-721 #edit-submitted-total{
  background-color: white;
  font-weight: bold;
  text-align: right;
}