.crm-price-amount-tax {
  display:none
}
#crm-container {
  margin-bottom: 3rem;
}

// Formulaire paiement

// Cacher le message de bienvenue

#crm-event-register-different {
  display: none;
}

div.crm-event-info-form-block {
  .event_fees-section {
    display: none;
  }
}

span.crm-price-amount-label-separator.option-AIR_FRANCE,
span.crm-price-amount-label-separator.option-SNCF,
span.crm-price-amount-label-separator.option-Demande_attestation {
  display: none;
}

// On cache le bouton transmettre que le SFR ne souhaite pas avoir
.page-civicrm-event-public-groupparticipantslisting {
  #transmit-button {
    display: none;
  }
}