
.page-faq-medecins-covid19, .page-faq-medecins-covid19-hors-vaccination {
  .main-container {
    padding-bottom: 50px;
    .view-faq {
      .views-widget{
        width:400px;
        max-width: 100%;
        display: inline-block;
        margin-left:10px;
        .form-type-select{
          width:400px;
          max-width: 100%;
          .form-control.chosen-container-multi{
            height:auto;
            border:0;
            min-width: 100%;
            background: none;
            .chosen-choices{
              border:unset;
              background:unset;
              box-shadow:unset;
              
              input{
                margin-top: 5px;
              }
            }
          }
          .form-control.chosen-container-multi:after {
            z-index:-9000;
          }
        }
      }
      .views-exposed-form {
        .views-exposed-widget{
          max-width: 100%;
          .btn {
            margin-top:0px;
          }
        }
      }


      .view-content{
        ul{
          padding: 0;
          li:before{
            display:none;
          }
          
          li{
            margin-top: 20px;
            border:1px solid #e9e5e2;
            border-radius: 5px;
            .faq-question-answer{
              .faq-question{
                background:#e9e5e2;
                padding: 8px;
                a{
                  color: #54455b;
                }
                
                .faq_keywords{
                  float:right;
                  ul{
                    text-align:right;
                    li{
                      display:inline;
                      background:white;;
                      border-radius:3px;
                      padding:3px;
                      font-size:0.9em;
                    }
                  }
                }
              }
              .faq-question.faq-qa-visible{
                a:before{
                  content: " v ";
                }
              }
              .faq-question:not(.faq-qa-visible){
                a:before{
                  content: " > ";
                }
              }
              .faq-answer{
                padding:15px;
              }
            }
          }
        }
      }
    }
    
    .div-form-rpps{
      background:rgba(100, 100, 100, 0.8);
      position:fixed; 
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:9000;
      .form-rpps{
        background:white;
        padding:30px 50px;
        width:80%;
        max-width:500px;
        text-align:center;
        margin:150px auto 0 auto;
        
        h2{
          margin:10 0 40px 0 ;
        }
        
        p{
          
        }
        
        #form_message_rpps_invalide{
          font-weight:bold;
          color:red;
          display:none
        }
        
        input[type='submit']{
          background: #54455b;
          color: white;
          border: unset;
          box-shadow: unset;
          padding: 6px 12px;
          margin-top: 15px;
        }
      }
    }
  }
}


.page-covid-19-en-direct form .views-exposed-form .form-type-select{
  width: 500px;
  max-width:auto;
}
.page-covid-19-en-direct form .views-exposed-form .form-type-select .chosen-container{    
  border: unset;
}
.page-covid-19-en-direct form .views-exposed-form .chosen-choices{
    border: unset;
    background: unset;
    box-shadow: unset;
}
.page-covid-19-en-direct form .views-exposed-form .chosen-choices input[type=text]{
  margin-top: 5px;
}

.page-covid-19-en-direct form .views-exposed-form .views-submit-button{
  padding-top: 4px;
}


.page-covid-19-en-direct {
  .view-covid-en-direct::before {
    content: "Covid-19 : Informations";
    font-size: 18px;
    padding-bottom: 5px;
    border-bottom: 1px solid #b7aba0;
    width: 100%;
    display: block;
    color: #b7aba0;
    margin-bottom: 20px;
    padding: 0;
  }
  .item-list {
    h3 {
      margin: 20px 0 0 0;
      text-align:unset;
      font-size:15px;
      padding-bottom:5px;
      /*border-bottom:1px solid #b7aba0;*/
      color: #b7aba0;
    }
    
    ul{
      padding:0; 
      margin:10px 0 30px 15px;
  
      li:before {
        display:none;
      }
      li {
        list-style:none;
        padding:0;
        margin-bottom:20px;
        
        .actu_mots_cles:before {
          content: "\f02c";
          font-family: "Font Awesome 5 Free";
          margin-right: 5px;
        }
        
        .actu_mots_cles, .actu_date {
          /*font-size:0.8em;*/
          color:#54455b;
          display:inline;
          font-weight:bold;
          font-size: 13px;
        }
        
        .actu_date:before {
          content: "";
          font-family: "Font Awesome 5 Free";
          margin-right: 5px;
        }
        
        .actu_date {
          margin-right: 10px;
        }
        
        .actu_titre a {
          color:#b7aba0;
          color:inherit;
          font-weight:normal
        }
        
        .actu_titre:before {
          content: "•";
          margin-right: 5px;
        }
      }
    }
  }
  .region-sidebar-second {
    padding-bottom: 50px;
  
    h2 {
      font-size:18px;
      padding-bottom:5px;
      border-bottom:1px solid #b7aba0;
      margin-top:0;
    }
  
    h2.green {
      color:#94c11f;
      border-bottom:1px solid #94c11f;
    }
  
    h4 {
      font-size:inherit;
    }
    
    ul {
      padding-left:20px;
    }
  }  
}