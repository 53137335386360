
// styles cards cohortes - registres - etudes

.view-cards-cohortes-registres-etudes {

  .views-exposed-form .views-exposed-widget .btn {
    margin-top: 1.5em;
  }

  .view-content {
    display: flex;
    max-width: 100%;
    flex-flow: wrap;
    margin-top: 3rem;

    .views-row {
      flex: 0 1 30%;
      flex-wrap: wrap;
      min-width: 30%;
      box-shadow: 0px 0px 8px gainsboro;
      margin: 1rem;
      border-bottom: 8px solid $brand-primary-cam;
      @media screen and (min-width: 767px) and (max-width: 991px) {
        flex: 0 1 46%;
        min-width: 46%;
      }
      @media screen and (max-width: 767px) {
        flex: 0 1 100%;
        min-width: 100%;
      }
      .views-field-field-type-etude {
        font-weight: unset;
        color: white;
        font-size: 1.4rem;
        .etude {
          // cohortes
          &-89 {
            background-color: $brand-quaternary;
            max-width: fit-content;
            margin-left: auto;
            padding: 0.3rem 1rem;
          }
          // observationelles
          &-90 {
            background-color: $brand-tertiary;
            max-width: fit-content;
            margin-left: auto;
            padding: 0.3rem 1rem;
          }
          // registres
          &-91 {
            background-color: $brand-primary-cam;
            max-width: fit-content;
            margin-left: auto;
            padding: 0.3rem 1rem;
          }
          //snds          
          &-92 {
            background-color: $brand-event;
            max-width: fit-content;
            margin-left: auto;
            padding: 0.3rem 1rem;
          }
        }

      }
      .views-field-title {
        padding: 0.8rem 1.5rem;
        border-bottom: 1px solid grey;
        a {
          color: $brand-primary-cam;
        }
      }
      .views-field-field-statut {
        padding: 0.8rem 1.5rem;
        border-bottom: 1px solid grey;
        text-align: right;
        color: $brand-primary-cam;
        font-size: 1.4rem;
        font-weight: normal;

        .statut {
          &-86 {
            color: $brand-tertiary;
            &::before  {
              content: "• ";
             }
          }
          &-87 {
            color: $brand-quaternary;
            &::before  {
              content: "• ";
             }
          }
          &-85 {
            color: $brand-event;
            &::before  {
              content: "• ";
             }
          }
          &-88 {
            color: red;
            &::before  {
              content: "• ";
             }
          }
        }
      }
      .views-field-field-titre-recherche-detail, .views-field-field-maladie, .views-field-field-type-patients, .views-field-field-ville {
        padding: 1rem 1.5rem; 
        .views-label-field-titre-recherche-detail, .views-label-field-maladie, .views-label-field-type-patients, .views-label-field-ville {
          color: $brand-secondary;
        }
      }
      .views-field-field-ville {
        display: inline-flex;
        &::before {
          left: 0;
          right: 0;
          margin: auto;
          font-family: $font-icon;
          content: $fa-var-map-marker-alt;
          font-size: 2rem;
          font-weight: bold;
          color: $brand-primary;
          padding-right: 1rem;
          margin-top: inherit;
        }
        .views-label-field-ville {
        display: none;
        }
      }
      //link En savoir plus
      .views-field-view-node {
        padding: 0.8rem 1rem 1.5rem;
        text-align: right;
        a {
          color: $brand-primary-cam;
          font-size: 1.4rem;
          font-weight: bold;
          text-decoration: underline;
        }
        }
    }
  }
    // Pagination

    .pagination {
      border-top: none;
      margin: 2rem 0;
      .pager-first, .pager-last {
        a {
          background-color:  $brand-primary;
            &:hover {
              background-color: $brand-tertiary;
              color: white;
        }
      }
      }
      .active {
        span {
          background-color:  $brand-tertiary;
          border-radius: 4px;
        }
      }

}

}

// styles cards page détail

// type
.node-cohortes-registres-etudes {
  .en-tete-cohortes {
    box-shadow: 0px 0px 8px gainsboro;
    padding: 2rem;
    margin-bottom: 4rem;
    border-bottom: 8px solid $brand-primary-cam;

    .type {
      &-Cohorte {
        background-color: $brand-quaternary;
        max-width: fit-content;
        margin-right: auto;
        font-weight: unset;
        font-size: 1.4rem;
        padding: 0.3rem 1rem;
        .field-type-taxonomy-term-reference {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: white !important;
            }
          }
        }
      }
      &-Registre {
        background-color: $brand-primary-cam;
        max-width: fit-content;
        margin-right: auto;
        padding: 0.3rem 1rem;
        .field-type-taxonomy-term-reference {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: white !important;
            }
          }
        }
      }
      &-Observationnelle {
        background-color: $brand-tertiary;
        max-width: fit-content;
        margin-right: auto;
        padding: 0.3rem 1rem;
        .field-type-taxonomy-term-reference {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: white !important;
            }
          }
        }
      }
      &-SNDS {
        background-color: $brand-event;
        max-width: fit-content;
        margin-right: auto;
        padding: 0.3rem 1rem;
        .field-type-taxonomy-term-reference {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: white !important;
            }
          }
        }
      }
      .field-item {
    }
    }

    //statut
    .statut {
      &-Recrutement {
        text-align: right;
        .field-name-field-statut {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: $brand-tertiary;
              &::before  {
                content: "• ";
               }
            }
          }
        }
      }
      &-Suivi{
        text-align: right;
        .field-name-field-statut {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: $brand-quaternary;
              &::before  {
                content: "• ";
               }
            }
          }
        }
      }
      &-Planifiée {
        text-align: right;
        .field-name-field-statut {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: $brand-event;
              &::before  {
                content: "• ";
               }
            }
          }
        }
      }
      &-Terminé {
        text-align: right;
        .field-name-field-statut {
          margin-left: unset;
          .field-items {
            &::before  {
              content: unset;
             }
             a {
              color: red;
              &::before  {
                content: "• ";
               }
            }
          }
        }
      }
    }

  //content autres champs

  .detail-en-tete-cohortes {
    padding-top: 2.5rem;
    display: flex;
    max-width: 100%;
    flex-flow: wrap;
    // ensemble des field labels
    .field-label {
      @media screen and (max-width: 991px) {
        box-sizing: unset;
      }
    }
    .field {
      flex: 0 1 30%;
      flex-wrap: wrap;
      min-width: 30%;
      padding-bottom: 2.5rem;
      padding-right: 2rem;
      @media screen and (min-width: 767px) and (max-width: 991px) {
        flex: 0 1 46%;
        min-width: 46%;
      }
      @media screen and (max-width: 767px) {
        flex: 0 1 100%;
        min-width: 100%;
      }
      .field-label {
        color: $brand-secondary;
      }
    }
    .field-type-taxonomy-term-reference {
      margin-left: 0;
    }
    .field-name-field-titre-recherche-detail {
      color: $brand-primary-cam;
      font-weight: bold;
    }
    .field-name-field-reference {
      .field-item {
        font-weight: bold;
      }

    }
    .field-name-field-maladie {
       .field-label {
        font-weight: bold;
       }
      a {
        font-weight: bold;
        color: black;
      }
    }
    .field-name-field-region {
      .field-label {
        font-size: 0px;
        &::before {
          left: 0;
          right: 0;
          margin: auto;
          font-family: $font-icon;
          content: $fa-var-map-marker-alt;
          font-size: 2rem;
          font-weight: bold;
          color: $brand-primary;
          padding-right: 1rem;
          margin-top: inherit;
        }
       }
      .field-items {
        &::before {
          content: none;
        }
      } 
      a {
        font-weight: bold;
        color: $brand-primary;
      }
    }

    .field-name-field-contact-mail, .field-name-field-blog-site-internet {
      a {
        color: black;
        font-weight: normal;
        text-decoration: underline;
      }
    }

  }

  }
}