.front {
  .view-actualites {
    max-width: 1170px;
    margin: auto;
  }
  #block-views-actualites-block {
    background-color: $brand-secondary-cam;
    @media only screen and (max-width: 767px) {
      padding: 1rem 0;
    }
    @media only screen and (min-width: 768px) {
      padding: 2rem 0 4rem 0;
    }
    .view-content {
      margin-bottom: 50px;
      justify-content: space-between;
    }
    h2 {
      color: $brand-primary;
      font-weight: 300;
      text-align: center;
      margin-bottom: 3rem;
    }
    .actu {
      background-color: $gray-lightest;
      box-shadow: 0px 7px 15px -3px $gray;
      @media only screen and (min-width: 768px) {
        width: 32%;
      }

    }
    .view-actualites .actu .thumbnail .btn {
      right: 2.3rem;
    }
    .date {
      margin-left: 20px;
      color: $brand-secondary;
      &::before {
        position: absolute;
        margin-left: -2rem;
        font-family: $font-icon;
        content: $fa-var-calendar-alt;
        color: $brand-secondary;
        font-size: 1.5rem;
        font-weight: 100;
      }
    }
    .card-title {
      a {
        color: $brand-primary;
        &:hover {
          color: $brand-primary-cam;
          text-decoration: none;
        }
      }
    }
    .more-link {
      text-align: center;
      margin-bottom: 3rem;
    }
  }
}
